import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Media,
  CardHeader,
  CardFooter,
  Badge
} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import Banner from './banner'
// import DashboardCarousel from "./carousel";
import Portfolio from './portfolio'
// import Partner from './partner'

import ReferralModal from './referral_modal'
import AnnouncementModal from './announcement_modal'
import Carousel from './carousel'

// import CarouselItem from './carousel'
// import Carousel from 'react-elastic-carousel'
const ResponsiveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ResponsiveVerifyButton = styled(Button)`
  background-color: #b35855 !important;
  border-color: #b35855 !important;
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: flex-end;
  }
`

const ResponsiveInviteButton = styled(Button)`
  margin-left: 12px;
  @media (max-width: 575px) {
    align-self: flex-end;
  }
`

const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`

const Dashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const promotions = useSelector((state) => state.promotion.promotions)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const bank = useSelector((state) => _.last(state.user.banks))
  // const [activeTab, setActiveTab] = useState('wallet')
  const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)
  const init = async () => {
    // const walletResponse = await WalletService.get()
    // dispatch({ type: 'SET_WALLETS', wallets: walletResponse.wallets })
    // dispatch({
    //   type: 'SET_BALANCE',
    //   balance: walletResponse.wallet_balance
    // })
    // dispatch({
    //   type: 'SET_TOTAL_DEPOSIT',
    //   totalDeposit: walletResponse.total_deposit
    // })
    // dispatch({
    //   type: 'SET_TOTAL_WITHDRAWAL',
    //   totalWithdrawal: walletResponse.total_withdrawal
    // })
    // dispatch({
    //   type: 'SET_TOTAL_ACCOUNT_DEPOSIT',
    //   totalAccountDeposit: walletResponse.total_account_deposit
    // })
    // dispatch({
    //   type: 'SET_TOTAL_ACCOUNT_WITHDRAWAL',
    //   totalAccountWithdraw: walletResponse.total_account_withdraw
    // })
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: 'none' })
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // modal
  const [showReferral, setShowReferral] = useState(false)
  const toggleModal = () => {
    setShowReferral(!showReferral)
  }

  const toggleAnnouncementModal = () => {
    dispatch({ type: 'SET_ANNOUNCEMENTS_INIT', showAnnouncement: false })
  }

  return (
    <>
      <Breadcrumb title="Dashboard" />
      <Container style={{ paddingBottom: 40 }}>
        {/* <Row>
          <Col> */}
        {/* {verification?.status !== 1 && (
              <Card>
                <CardBody
                  className="p-4"
                  style={{ backgroundColor: '#ffc4c4', borderRadius: '5px' }}
                >
                  <ResponsiveCard>
                    <ResponsiveParagraph style={{ color: '#963737', fontWeight: 'bold' }}>
                      Your profile isn’t verified. Please verify your profile to take full advantage
                      of our services!
                    </ResponsiveParagraph>
                    <ResponsiveVerifyButton
                      color="primary"
                      onClick={() => {
                        navigate(`${process.env.PUBLIC_URL}/account#verification`)
                      }}
                    >
                      Verify
                    </ResponsiveVerifyButton>
                  </ResponsiveCard>
                  {verification?.status === 3 && verification?.reason && (
                    <>
                      <ResponsiveParagraph style={{ color: '#ab0505' }}>
                        Your recent application was rejected
                      </ResponsiveParagraph>
                      <ResponsiveParagraph
                        style={{ color: '#ab0505' }}
                      >{`Reason: ${verification?.reason}`}</ResponsiveParagraph>
                    </>
                  )}
                </CardBody>
              </Card>
            )} */}

        {/* <Card>
          <CarouselItem promotions={promotions} />
        </Card> */}

        <Row style={{ justifyContent: 'center', alignItems: 'center' }} className="m-2">
          <Carousel />
        </Row>

        <h4 style={{ color: '#512367' }} className="mt-5 mb-2">
          My Portfolio
        </h4>
        <Row>
          <Col>
            <Portfolio />
          </Col>
        </Row>
      </Container>

      {
        <Modal isOpen={showAnnouncement} backdrop={true} centered={true}>
          <ModalHeader
            style={{
              backgroundColor: 'rgb(81, 35, 103)',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              border: '0px'
            }}
          >
            Important Announcement
          </ModalHeader>
          <ModalBody>
            <AnnouncementModal />
            <Button
              style={{ display: 'block', margin: '0 auto' }}
              color="primary"
              onClick={toggleAnnouncementModal}
            >
              Noted
            </Button>
          </ModalBody>
        </Modal>
      }
    </>
  )
}

export default Dashboard
