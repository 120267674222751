import React from 'react'
import { Minimize, User } from 'react-feather'
import { translate } from 'react-switch-lang'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'

import DarkMode from '../../components/header/dark_mode'
import Profile from '../../components/header/profile'

const HighlightDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid rgb(249, 182, 0);
  }
`

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 575px) {
    display: block;
  }
`

const Rightbar = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const verification = useSelector((state) => _.last(state.user.verifications))

  // full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <div className="nav-right col-10 pull-right right-menu">
        <ul className="nav-menus">
          <li>
            {/* <HighlightDiv
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/account`)
              }}
            >
              {user?.profile != null && !_.isEmpty(user.profile) ? (
                <i
                  className="fa fa-check-circle"
                  style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                ></i>
              ) : (
                <i
                  className="fa fa-times-circle"
                  style={{ color: 'red', fontSize: 20, marginRight: 4 }}
                ></i>
              )}
              My Details
            </HighlightDiv> */}
          </li>
          <li>
            {/* <HighlightDiv
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/account#verification`)
              }}
            >
              {verification?.status === 1 ? (
                <i
                  className="fa fa-check-circle"
                  style={{ color: 'green', fontSize: 20, marginRight: 4 }}
                ></i>
              ) : (
                <i
                  className="fa fa-times-circle"
                  style={{ color: 'red', fontSize: 20, marginRight: 4 }}
                ></i>
              )}
              Verification
            </HighlightDiv> */}
          </li>
          <MobileOnly>
            <User
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/account`)
              }}
            />
          </MobileOnly>
          <li className="maximize">
            <DarkMode />
          </li>
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="onhover-dropdown p-0">
            <Profile />
          </li>
        </ul>
      </div>
    </>
  )
}
export default translate(Rightbar)
