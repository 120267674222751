import React from "react"
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap"

const NationalId = () => {
  return (
    <>
      <Row>
        <Col md="12">
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              Must be valid and have expiry period longer than one month at the time of verification
            </ListGroupItem>
            <ListGroupItem>All information and details must be clearly visible</ListGroupItem>
            <ListGroupItem>All scanned files or photos must be in color</ListGroupItem>
            <ListGroupItem>Must contain signature (if applicable)</ListGroupItem>
            <ListGroupItem>All submitted documents must not be damaged</ListGroupItem>
            <ListGroupItem>
              Provide a photo or scan of the document, or send a photo of the document from a mobile
              device
            </ListGroupItem>
            <ListGroupItem>Both side of ID card must be uploaded</ListGroupItem>
            <ListGroupItem>The edges of documents should not be cut off</ListGroupItem>
          </ListGroup>
        </Col>
        {/* <Col md="6">
          <img src="/idcard.jpg" alt="national_id" style={{ maxWidth: "100%" }} />
        </Col> */}
      </Row>
    </>
  )
}

export default NationalId
