export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const convertNumberFloatZero = (number) => {
  if (!isNumeric(number)) {
    return 'NaN'
  }
  let num = Number(number)

  var numberFloat = num.toFixed(3)
  var splitNumber = numberFloat.split('.')
  var cNumberFloat = num.toFixed(2)
  var cNsplitNumber = cNumberFloat.split('.')
  var lastChar = splitNumber[1].substr(splitNumber[1].length - 1)
  if (lastChar > 0 && lastChar < 5) {
    cNsplitNumber[1]--
  }
  return Number(splitNumber[0]).toLocaleString('en').concat('.').concat(cNsplitNumber[1])
}

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const titleCase = (string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join('')
}

export const bankList = [
  {
    name: 'CIMB Bank',
    code: 'CIMB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Hong Leong Bank',
    code: 'HLB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Maybank',
    code: 'MBB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Public Bank',
    code: 'PBB.MY',
    country: 'Malaysia'
  },
  {
    name: 'UOB Bank',
    code: 'UOB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Ambank',
    code: 'ARB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Affin Bank',
    code: 'AFB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Alliance Bank',
    code: 'ALB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Standard Chartered Bank',
    code: 'SCB.MY',
    country: 'Malaysia'
  },
  {
    name: 'RHB Bank',
    code: 'RHB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Islam Malaysia',
    code: 'BIMB.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Rakyat Malaysia',
    code: 'BKR.MY',
    country: 'Malaysia'
  },
  {
    name: 'Bank Simpanan Nasional Malaysia',
    code: 'BSN.MY',
    country: 'Malaysia'
  }
]
