import React, { useState } from 'react'
import { Card, CardBody, Table, Button } from 'reactstrap'
import BankForm from './form'
import { bankList } from '../../../network/constants'
import _ from 'lodash'

const translateStatus = (status) => {
  switch (status) {
    case 0:
      return 'Pending'
    case 1:
      return 'Verified'
    case 2:
      return 'Failed'
    case 3:
      return 'Rejected'
    default:
      return '-'
  }
}

const translateBank = (bank) => {
  const findBank = _.find(bankList, { code: bank })
  return findBank?.name ?? bank
}

const BankCard = ({ bank }) => {
  const [isEditable, setIsEditable] = useState(false)

  return (
    <Card>
      <CardBody>
        {isEditable ? (
          <>
            <BankForm bank={bank} setIsEditable={setIsEditable} />
          </>
        ) : (
          <>
            <Table borderless>
              <tbody>
                <tr>
                  <td width="50%">Account holder</td>
                  <td>{bank?.account_holder ?? '-'}</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td>{bank?.bank_branch ?? '-'}</td>
                </tr>
                <tr>
                  <td>Account number</td>
                  <td>{bank.account_number ?? '-'}</td>
                </tr>
                <tr>
                  <td>Swift code</td>
                  <td>{bank?.swift_code ?? '-'}</td>
                </tr>
                <tr>
                  <td>Bank name</td>
                  <td>{translateBank(bank?.bank_name)}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td
                    style={{
                      fontWeight: 'bold',
                      color: bank.status === 1 ? 'green' : bank.status === 3 ? 'red' : 'black'
                    }}
                  >
                    {translateStatus(bank?.status)}
                  </td>
                </tr>
                {bank?.reason && (
                  <tr>
                    <td>Reason</td>
                    <td>{bank.reason}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {bank?.status === 0 && (
              <Button
                className="pull-right"
                color="primary"
                onClick={() => {
                  setIsEditable(true)
                }}
              >
                Edit
              </Button>
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default BankCard
