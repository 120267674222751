import React from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'

const columns = [
  {
    name: 'Txn.No',
    width: '200px',
    selector: 'txn_id'
    // sortable: true
  },
  {
    name: 'Date',
    selector: 'updated_at',
    // sortable: true,
    width: '200px',
    format: (row) => {
      return moment(row.updated_at).format('yyyy-MM-DD hh:mmA')
    }
  },
  {
    name: 'Wallet',
    selector: 'currency_unit'
    // sortable: true
  },
  {
    name: 'Action',
    selector: 'txn_type',
    // sortable: true,
    format: (row) => {
      switch (row.txn_type) {
        case 0:
          return 'Credit'
        case 1:
          return 'Debit'
        default:
          return '-'
      }
    }
  },
  {
    name: 'WOTA Token',
    selector: 'amount'
    // sortable: true
  },
  // {
  //   name: 'WOTA Rate',
  //   selector: 'currency_rate'
  //   // sortable: true
  // },
  {
    name: 'USD Value',
    selector: 'base_price'
    // sortable: true
  },
  {
    name: 'Status',
    selector: 'status',
    // sortable: true,
    format: (row) => {
      switch (row.status) {
        case 0:
          return <span>Pending</span>
        case 1:
          return <span style={{ color: 'green' }}>Success</span>
        case 2:
          return <span style={{ color: 'red' }}>Failed</span>
        case 3:
          return <span style={{ color: 'red' }}>Rejected</span>
        default:
          return '-'
      }
    }
  },
  {
    name: 'Description',
    selector: 'description',
    width: '200px'
  }
  // {
  //   name: 'Reason',
  //   selector: 'reason',
  //   sortable: true,
  //   width: '400px',
  //   format: (row) => {
  //     switch (row.txn_type) {
  //       case 0:
  //         return row?.deposit?.reason ?? ''
  //       case 1:
  //         return row?.withdraw?.reason ?? ''
  //       case 2:
  //         return row?.transfer?.reason ?? ''
  //       case 10:
  //         return row?.account_deposit?.reason ?? ''
  //       case 11:
  //         return row?.account_withdraw?.reason ?? ''
  //       default:
  //         return ''
  //     }
  //   }
  // }
]

const WalletTable = ({ transactions }) => {
  return (
    <>
      <h5>Recent Transactions</h5>
      <DataTable noHeader data={transactions} columns={columns} striped={true} center={true} />
    </>
  )
}

export default WalletTable
