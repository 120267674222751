import React, { useState } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
  Col,
  CardBody,
  Badge,
  Card
} from 'reactstrap'

import { Link } from 'react-router-dom'

import { AlertCircle } from 'react-feather'

const items = [
  // {
  //   src: 'https://th-kyc-backup.s3-ap-southeast-1.amazonaws.com/Partnership-04+(1).jpg',
  //   altText: 'Slide 1',
  //   caption: 'Slide 1',
  //   publish: true,
  //   description: 'Want to become our partnership?',
  //   url: '',
  //   proceed_url: '/support',
  // },
  {
    src: 'https://wota-server-s3.s3-ap-southeast-1.amazonaws.com/banner.png',
    altText: 'Slide 2',
    caption: 'Slide 2',
    publish: true,
    description: 'Download Wota App to start you investment',
    url: 'https://enterwota.com/#pricing',
    proceed_url: 'https://enterwota.com/'
  }
]

const Example = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img className="d-block w-100" src={item.src} alt={item.altText} style={{ width: '80%' }} />

        <div style={{ display: 'flex' }}>
          <Col style={{ padding: '0' }}>
            <a href={item.proceed_url} target="_blank">
              <Button
                disabled={!item.publish}
                id="mobileFontSize"
                color="primary"
                style={{ width: '100%', borderRadius: '0' }}
              >
                Download
              </Button>
            </a>
          </Col>
          <Col style={{ padding: '0' }}>
            <a href={item.url} target="_blank">
              <Button
                disabled={item.url === ''}
                id="mobileFontSize"
                style={{ width: '100%', borderRadius: '0' }}
              >
                More Information
              </Button>
            </a>
          </Col>
        </div>
      </CarouselItem>
    )
  })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />

      {slides}
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
    </Carousel>
  )
}

export default Example
