import React, { useEffect, useState } from 'react'
import WalletOption from '../options'
import PortfolioHistory from './portfolio'
// import MT5History from "./mt5"

const History = ({ refresh }) => {
  const [option, setOption] = useState()

  // reset options on tab change
  useEffect(() => {
    setOption(null)
  }, [refresh])

  return (
    <>
      {/* {option == null ? (
        <WalletOption setOption={setOption} type="history" />
      ) : (
        <>
          <WalletHistory setOption={setOption} />
        </>
      )} */}
      <PortfolioHistory setOption={setOption} />
    </>
  )
}

export default History
