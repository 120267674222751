import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Link } from 'react-router-dom'

import FeedbackService from '../../network/services/feedback'

const columns = [
  {
    name: 'Ticket',
    selector: 'id',
    sortable: true
  },
  {
    name: 'Subject',
    selector: 'subject',
    sortable: true
  },
  {
    name: 'Message',
    selector: 'message',
    sortable: true
  },
  {
    name: 'Updated',
    selector: 'created_at',
    sortable: true,
    width: '200px',
    format: (row) => {
      return moment(row.updated_at).format('yyyy-MM-DD hh:mmA')
    }
  },
  {
    name: 'Action',
    selector: 'actions',
    format: (row) => {
      return <Link to={`support/${row.id}`}>Reply</Link>
    }
  }
]

const TicketTable = ({ refresh }) => {
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])

  const handlePageChange = async (page) => {
    const result = await FeedbackService.getAll({ page })
    setData(result.feedbacks.data)
  }

  const init = async () => {
    const result = await FeedbackService.getAll()
    // console.log(result.feedbacks)

    setTotal(result.feedbacks?.meta?.total ?? 0)
    setData(result.feedbacks?.data ?? [])
  }

  useEffect(() => {
    init()
  }, [refresh])

  return (
    <>
      <h5>Tickets</h5>
      <DataTable
        noHeader
        data={data}
        columns={columns}
        striped={true}
        center={true}
        pagination
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={total}
        onChangePage={handlePageChange}
        style={{ paddingBottom: 8 }}
      />
    </>
  )
}

export default TicketTable
