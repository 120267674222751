import {
  SET_ACTIVE_VERIFICATION,
  SET_ID_FRONT,
  SET_ID_BACK,
  SET_ADDRESS_FRONT,
  SET_ADDRESS_BACK
} from '../actionTypes'

const initial_state = {
  activeTab: 'passport',
  idFront: {
    file: null,
    error: null
  },
  idBack: {
    file: null,
    error: null
  },
  addressFront: {
    file: null,
    error: null
  },
  addressBack: {
    file: null,
    error: null
  }
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACTIVE_VERIFICATION:
      return { ...state, activeTab: action.activeTab }

    case SET_ID_FRONT:
      return { ...state, idFront: action.idFront }

    case SET_ID_BACK:
      return { ...state, idBack: action.idBack }

    case SET_ADDRESS_FRONT:
      return { ...state, addressFront: action.addressFront }

    case SET_ADDRESS_BACK:
      return { ...state, addressBack: action.addressBack }

    default:
      return { ...state }
  }
}
