import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";

import Loader from "./layout/loader";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";
import Footer from "./layout/footer";

import ReactGA from 'react-ga'

const App = ({ children }) => {

  useEffect(()=>{
    ReactGA.initialize('UA-178645755-2')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <>
      <Loader />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />
          <div className="page-body">{children}</div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default App;
