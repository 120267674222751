import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Paragraph = styled.p`
  margin: 0;

  @media only screen and (max-width: 575px) {
    margin: auto;
  }
`

const Footer = (props) => {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col md="6" className="footer-copyright">
              <p className="mb-0">Contact us at</p>
              <p className="mb-0">support@enterwota.com</p>
            </Col>
            <Col
              sm="12"
              md="6"
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <Paragraph>Copyright © www.enterwota.com 2021</Paragraph>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
