import {
  SET_USER,
  // SET_BANK,
  SET_BANKS,
  SET_PROFILE,
  SET_AGENT,
  // SET_VERIFICATION,
  SET_VERIFICATIONS,
  SET_TWOFA
} from '../actionTypes'

const initial_state = {
  user: null,
  // bank: null,
  banks: null,
  profile: null,
  partners: null,
  // verification: null,
  verifications: null,
  address: null,
  twoFA: null
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user }

    // case SET_BANK:
    //   return { ...state, bank: action.bank }

    case SET_BANKS:
      return { ...state, banks: action.banks }

    case SET_PROFILE:
      return { ...state, profile: action.profile }

    case SET_AGENT:
      return { ...state, partners: action.partners }

    // case SET_VERIFICATION:
    //   return { ...state, verification: action.verification }

    case SET_VERIFICATIONS:
      return { ...state, verifications: action.verifications }

    case SET_TWOFA:
      return { ...state, twoFA: action.twoFA }

    default:
      return { ...state }
  }
}
