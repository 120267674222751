import React, { useState } from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input
} from 'reactstrap'
import { useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'

import UserService from '../../../network/services/user'
import AuthService from '../../../network/services/auth'

const TwoFactorAuthentication = () => {
  // modal
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const [authCode, setAuthCode] = useState(null)
  const [errors, setErrors] = useState({ verification: null, twoFA: null })
  const email = useSelector((state) => state.user.user.email)
  const twoFA = useSelector((state) => state.user.twoFA)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const handle2fa = async () => {
    try {
      let result
      result = await UserService.update2fa({ auth_code: authCode })

      // if (twoFA != null) {
      //   // disable
      // } else {
      //   // enable
      // }

      // console.log(result)

      if (result.user) {
        setShowModal(false)

        SweetAlert.fire({
          title: '2FA status updated successfully!',
          icon: 'success'
        }).then(() => {
          window.location.reload()
        })
      }
    } catch (error) {
      console.log(error)
      setErrors({ twoFa: error.message })
    }
  }

  if (userVerification?.status === 1) {
    return (
      <>
        <Card>
          <CardHeader>
            <div style={{ display: 'flex' }}>
              <h5>Two-Factor Authentication</h5>
              <Badge color={twoFA != null ? 'success' : 'danger'} pill className="ml-2">
                {twoFA != null ? 'On' : 'Off'}
              </Badge>
            </div>
            <div className="p-1" />
            <p style={{ margin: 0 }}>
              Protect your account and your funds by adding a second authentication method. Each
              time you login or initiate a withdrawal, you will be asked for your password and
              authentication code.
            </p>
          </CardHeader>
          <CardBody>
            <div>
              <h6>How to use Two-Factor Authentication</h6>
              <p style={{ margin: 0 }}>
                When you signin, after entering your password you will be asked for a code. The code
                will be sent to your email.
              </p>
              <div className="p-2" />
              <Container>
                <Card>
                  <CardBody>
                    <p style={{ margin: 0 }}>
                      Your Wallet is <b>{twoFA != null ? '' : 'not'}</b> protected with Two-Factor
                      Authentication
                    </p>
                    <div className="p-0" />
                    {twoFA == null && (
                      <p style={{ margin: 0 }}>
                        Secure login and /or payout with a unique code sent to your email.
                      </p>
                    )}
                    <div className="p-1" />
                    <Button
                      color={twoFA != null ? 'danger' : 'success'}
                      onClick={async () => {
                        if (email != null) {
                          const codeResult = await AuthService.sendVerification({
                            email: email
                          })

                          if (codeResult.success) {
                            setShowModal(true)
                          } else {
                            setErrors({ verification: codeResult.message })
                          }
                        } else {
                          setErrors({ verification: 'Missing email' })
                        }
                      }}
                    >
                      {twoFA != null ? 'Disable' : 'Enable'} 2FA
                    </Button>
                    <div>
                      <span style={{ color: 'red' }}>{errors?.verification}</span>
                    </div>
                  </CardBody>
                </Card>
              </Container>
            </div>
            {/* <div>
            <h6>Trusted device list</h6>
            <p style={{ margin: 0 }}>Trusted devices do not require Two-Factor Authentication</p>
            <div className="p-2" />
            <Container>
              <Card>
                <CardBody>
                  <p style={{ margin: 0 }}>No trusted device Found</p>
                </CardBody>
              </Card>
            </Container>
          </div> */}
          </CardBody>
        </Card>

        {/* <Card>
        <CardBody>
          <h6>Did you lose your phone?</h6>
          <p style={{ margin: 0 }}>
            Use your recovery key if you are unable to use Google Authenticator. If you do not have
            your recovery key, you can generate new ones.
          </p>
          <div className="p-1" />
          <Button color="primary">Regenerate Recovery Keys</Button>
        </CardBody>
      </Card> */}

        {showModal && (
          <Modal isOpen={showModal} backdrop={true} centered={true}>
            <ModalHeader toggle={toggleModal}>Please verify your identity</ModalHeader>
            <ModalBody>
              <p className="mb-1">Check your email for verification code</p>
              <Input
                className="form-control"
                placeholder="Verification Code"
                required
                onChange={(e) => {
                  setAuthCode(e.target.value)
                }}
              />
              <span style={{ color: 'red' }}>{errors?.twoFA}</span>
              <Button
                className="mt-3 pull-right"
                onClick={() => {
                  handle2fa()
                }}
              >
                Submit
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    )
  }

  return (
    <Card>
      <CardBody>
        Please complete the <a href="#verification">KYC Verification Process</a> to continue the
        process.
      </CardBody>
    </Card>
  )
}

export default TwoFactorAuthentication
