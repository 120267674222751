import React, { useRef, useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import moment from 'moment'
import { useSelector } from 'react-redux'

import Breadcrumb from '../../../layout/breadcrumb'
import FeedbackService from '../../../network/services/feedback'

const SupportReply = () => {
  const { id } = useParams()
  const [feedback, setFeedback] = useState([])
  const user = useSelector((state) => state.user.user)

  const fetchFeedback = async () => {
    const result = await FeedbackService.get(id)
    // console.log(result)
    setFeedback(result.feedbacks)
  }

  useEffect(() => {
    fetchFeedback()
    // eslint-disable-next-line
  }, [])

  const { register, handleSubmit, errors, setValue, reset } = useForm()
  const submitUpdate = async (data) => {
    try {
      const result = await FeedbackService.reply(id, data)
      if (result.feedback) {
        setFeedback(result.feedback)
        reset()
      }
    } catch (error) {
      console.log(error)
      SweetAlert.fire({
        title: error.message,
        icon: 'error'
      })
    }
  }

  const messageEl = useRef(null)
  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  return (
    <>
      <Breadcrumb title="Customer Support" />
      <Container>
        <Form
          onSubmit={handleSubmit(submitUpdate)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0.75rem'
          }}
        >
          <Row>
            <Col className="call-chat-body">
              <Card>
                <CardBody className="p-0">
                  <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                      <div className="chat">
                        <div className="chat-history chat-msg-box custom-scrollbar" ref={messageEl}>
                          <ul>
                            {feedback?.replies?.length > 0 ? (
                              feedback?.replies?.map((item, index) => {
                                return (
                                  <li key={index} className="clearfix">
                                    <div
                                      className={`message my-message ${
                                        item.user_id !== user?.id ? '' : 'float-right'
                                      }`}
                                    >
                                      <div
                                        className="message-data"
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                      >
                                        <span className="message-data-time">{item.user?.name}</span>
                                        <span className="message-data-time">
                                          {moment(item.created_at).format('yyyy-MM-DD hh:mmA')}
                                        </span>
                                      </div>
                                      {item.content}
                                    </div>
                                  </li>
                                )
                              })
                            ) : (
                              <div style={{ textAlign: 'center' }}>
                                <h5 style={{ marginTop: 12 }}>Start a conversation</h5>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div className="chat-message clearfix">
                          <Row>
                            <Col xl="12" className="d-flex">
                              <InputGroup className="text-box">
                                <Input
                                  type="text"
                                  className="form-control input-txt-bx"
                                  placeholder="Type a message......"
                                  name="content"
                                  innerRef={register}
                                />
                                <InputGroupAddon addonType="append">
                                  <Button color="primary">SEND</Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default SupportReply
