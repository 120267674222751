import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import _ from 'lodash'
import styled from 'styled-components'

import AddressTable from './address_table'
import IdentityTable from './identity_table'

import UserService from '../../../network/services/user'
import VerificationService from '../../../network/services/verification'
import FilesService from '../../../network/services/files'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 8px;
    }
  }
`

const ResponsiveButton = styled(Button)`
  background-color: #963737 !important;
  border-color: #b35855 !important;
`
const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`

const Verification = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [reupload, setReupload] = useState(false)
  const profile = useSelector((state) => state.user.profile)
  const userVerification = useSelector((state) => _.last(state.user.verifications))
  const verification = useSelector((state) => state.verification)

  const handleVerification = async (e) => {
    // console.log(verification)

    if (verification.idFront?.file == null) {
      console.log('missing id front')
      dispatch({ type: 'SET_ID_FRONT', idFront: { error: 'Please select one' } })
      return
    }

    if (verification.idBack?.file == null) {
      console.log('missing id back')
      dispatch({ type: 'SET_ID_BACK', idBack: { error: 'Please select one' } })
      return
    }

    if (verification.addressFront?.file == null) {
      console.log('missing address front')
      dispatch({ type: 'SET_ADDRESS_FRONT', addressFront: { error: 'Please select one' } })
      return
    }

    setIsBusy(true)

    try {
      const idFrontRes = await FilesService.create(verification.idFront?.file)
      const idBackRes = await FilesService.create(verification.idBack?.file)
      const addressFrontRes = await FilesService.create(verification.addressFront?.file)
      // const addressBackRes = await FilesService.create(verification.addressBack)

      const result = await VerificationService.create({
        type: verification.activeTab,
        id_front: idFrontRes.data,
        id_back: idBackRes.data,
        address_front: addressFrontRes.data,
        // debug
        address_back: addressFrontRes.data
      })

      // console.log(result)

      if (result?.verification) {
        SweetAlert.fire({
          title: 'Thank you, your account is pending for verification!',
          icon: 'success'
        }).then(() => {
          navigate(`${process.env.PUBLIC_URL}/dashboard`)
        })
        setIsBusy(false)
      }
    } catch (error) {
      setIsBusy(false)
      console.log(error)
      SweetAlert.fire({
        title: error.message,
        icon: 'error'
      })
    }
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  const init = async () => {
    const updatedUser = await UserService.getMyself()
    if (updatedUser.verifications) {
      dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // exist
  if (profile != null && !_.isEmpty(profile)) {
    return (
      <>
        {userVerification?.status !== 1 && (
          <Card>
            <CardBody style={{ backgroundColor: '#ffc4c4', borderRadius: '10px' }}>
              <StyledRow>
                <p style={{ margin: 0, color: '#963737' }}>
                  <strong>
                    Your profile isn’t verified. Please verify your profile to take full advantage
                    of our services!
                  </strong>
                </p>
                {userVerification?.status === 3 && !reupload && (
                  <ResponsiveButton color="primary" onClick={() => setReupload(true)}>
                    Reupload
                  </ResponsiveButton>
                )}
              </StyledRow>

              {userVerification?.status === 3 && userVerification?.reason && (
                <>
                  <ResponsiveParagraph style={{ color: '#ab0505' }}>
                    Your recent application was rejected
                  </ResponsiveParagraph>
                  <ResponsiveParagraph
                    style={{ color: '#ab0505' }}
                  >{`Reason: ${userVerification?.reason}`}</ResponsiveParagraph>
                </>
              )}
            </CardBody>
          </Card>
        )}

        {/* {userVerification?.status === 3 && userVerification?.reason && (
          <Card>
            <CardBody>
              <h5>Status: Rejected</h5>
              <p style={{ color: 'red', fontSize: 18 }}>{`Reason: ${userVerification?.reason}`}</p>
            </CardBody>
          </Card>
        )} */}

        <Card>
          <CardHeader>
            <h5>Identification</h5>
            <div className="p-1" />
            <p style={{ margin: 0 }}>Passport / National ID</p>
            <div className="p-0" />
            <p style={{ margin: 0 }}>
              Please upload an identification document, in colour, where your full name is
              displayed.
            </p>
          </CardHeader>
          <CardBody>
            <IdentityTable reupload={reupload} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Proof Of Address</h5>
            <div className="p-1" />
            <p style={{ margin: 0 }}>
              Proof of address that is not older than <strong>180 days</strong> (utility bill / bank
              statement / driving license / other official document with your name and address on
              it)
            </p>
          </CardHeader>
          <CardBody>
            <AddressTable reupload={reupload} />
          </CardBody>
        </Card>

        {(userVerification == null || reupload) && (
          <Card>
            <CardBody style={{ textAlign: 'center' }}>
              <h6>
                By clicking "Confirm & Proceed" you confirm that all the information provided is
                accurate and valid
              </h6>
              <Button color="primary" onClick={handleVerification} disabled={isBusy}>
                {isBusy ? 'Loading' : 'Submit'}
              </Button>
            </CardBody>
          </Card>
        )}
      </>
    )
  }

  // new
  if (profile != null && _.isEmpty(profile)) {
    return (
      <Card>
        <CardBody>
          Please complete the <a href="#details">My Details Verification Process</a> to continue the
          process.
        </CardBody>
      </Card>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default Verification
