import client from '../request'
import _ from 'lodash'

const getAll = (props = null) => {
  let page = props?.page ?? 1
  let limit = props?.limit ?? 10
  let sort = props?.sort ?? 'created_at:desc'
  let filters = props?.filters ?? null
  let filterString = ''

  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }

  return client.get(
    `/me/private_transactions?page=${page}&limit=${limit}&sort=${sort}${filterString}`
  )
}

const get = (id) => {
  return client.get(`/me/private_transactions/${id}`)
}

export default {
  getAll,
  get
}
