import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Card, CardBody, Spinner, Button } from 'reactstrap'
import BankForm from './form'
import BankCard from './bank_card'
import styled from 'styled-components'

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 8px;
    }
  }
`
const ResponsiveButton = styled(Button)`
  background-color: #963737 !important;
  border-color: #b35855 !important;
`
const ResponsiveParagraph = styled.p`
  margin: 0;

  @media (max-width: 575px) {
    margin-bottom: 8px;
  }
`
const BankDetail = () => {
  const userBank = useSelector((state) => _.last(state.user.banks))
  const banks = useSelector((state) => state.user.banks)
  const [createNew, setCreateNew] = useState(false)
  const [showRejected, setShowRejected] = useState(false)
  const [reupload, setReupload] = useState(false)

  if (banks != null) {
    if (banks?.length > 0) {
      return (
        <>
          {userBank?.status !== 1 && (
            <Card>
              <CardBody style={{ backgroundColor: '#ffc4c4', borderRadius: '10px' }}>
                <StyledRow>
                  <p style={{ margin: 0, color: '#963737' }}>
                    <strong>Your bank details isn’t verified. Please verify your bank!</strong>
                  </p>
                  {/* {userBank?.status === 3 && !reupload && (
                    <ResponsiveButton color="primary" onClick={() => setReupload(true)}>
                      Reupload
                    </ResponsiveButton>
                  )} */}
                </StyledRow>

                {userBank?.status === 3 && userBank?.reason && (
                  <>
                    <ResponsiveParagraph style={{ color: '#ab0505' }}>
                      Your recent application was rejected
                    </ResponsiveParagraph>
                    <ResponsiveParagraph
                      style={{ color: '#ab0505' }}
                    >{`Reason: ${userBank?.reason}`}</ResponsiveParagraph>
                  </>
                )}
              </CardBody>
            </Card>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setShowRejected(!showRejected)}>
              Show {showRejected ? 'Verified Only' : 'All'}
            </Button>
            {banks[banks.length - 1].status !== 0 &&
              (!createNew ? (
                <Button
                  color="primary"
                  onClick={() => {
                    setCreateNew(true)
                  }}
                >
                  Add a new bank
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    setCreateNew(false)
                  }}
                >
                  Cancel
                </Button>
              ))}
          </div>
          <div style={{ padding: 15 }} />

          {banks[banks.length - 1].status !== 0 && createNew && (
            <Card>
              <CardBody>
                <BankForm />
              </CardBody>
            </Card>
          )}

          {banks.map((bank) => {
            if (!showRejected && bank.status === 3) {
              return <React.Fragment key={bank.id}></React.Fragment>
            }

            return <BankCard key={bank.id} bank={bank} />
          })}
        </>
      )
    }

    return (
      <>
        <Card>
          <CardBody>
            <BankForm />
          </CardBody>
        </Card>
      </>
    )
  }

  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default BankDetail
