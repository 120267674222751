import React, { useState } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import Breadcrumb from '../../layout/breadcrumb'
import SupportForm from './form'
import TicketTable from './table'

const CustomerSupport = (props) => {
  const [refresh, setRefresh] = useState(null)

  return (
    <>
      <Breadcrumb title="Customer Support" />
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Submit a Ticket</h5>
              </CardHeader>
              <CardBody>
                <SupportForm setRefresh={setRefresh} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <TicketTable refresh={refresh} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CustomerSupport
