/* CUSTOMIZER */
export const ADD_COSTOMIZER = 'ADD_COSTOMIZER'
export const ADD_LAYOUT = 'ADD_LAYOUT'
export const ADD_SIDEBAR_TYPES = 'ADD_SIDEBAR_TYPES'
export const ADD_SIDEBAR_SETTINGS = 'ADD_SIDEBAR_SETTINGS'
export const ADD_COLOR = 'ADD_COLOR'
export const ADD_MIX_BACKGROUND_LAYOUT = 'ADD_MIX_BACKGROUND_LAYOUT'
export const ROUTER_ANIMATION = 'ROUTER_ANIMATION'

/**
 *  User
 */
export const SET_USER = 'SET_USER'
// export const SET_BANK = "SET_BANK"
export const SET_BANKS = 'SET_BANKS'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_AGENT = 'SET_AGENT'
// export const SET_VERIFICATION = "SET_VERIFICATION"
export const SET_VERIFICATIONS = 'SET_VERIFICATIONS'
export const SET_TWOFA = 'SET_TWOFA'

/**
 *  Header
 */
export const TRIGGER_SEARCH = 'TRIGGER_SEARCH'
export const TRIGGER_SIDEBAR = 'TRIGGER_SIDEBAR'

/**
 *  Sidebar
 */
export const SET_MAIN_MENU = 'SET_MAIN_MENU'
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'

/**
 *  Monitoring
 */
export const SET_MONITORING_ACCOUNT = 'SET_MONITORING_ACCOUNT'
export const TOGGLE_MONITORING_PRESENTATION = 'TOGGLE_MONITORING_PRESENTATION'

/**
 *  Wallet
 */
export const SET_WALLETS = 'SET_WALLETS'
export const SET_BALANCE = 'SET_BALANCE'
export const SET_TOTAL_DEPOSIT = 'SET_TOTAL_DEPOSIT'
export const SET_TOTAL_WITHDRAWAL = 'SET_TOTAL_WITHDRAWAL'
export const SET_TOTAL_ACCOUNT_DEPOSIT = 'SET_TOTAL_ACCOUNT_DEPOSIT'
export const SET_TOTAL_ACCOUNT_WITHDRAWAL = 'SET_TOTAL_ACCOUNT_WITHDRAWAL'

/**
 *  Account
 */
export const SET_ACCOUNTS = 'SET_ACCOUNTS'

/**
 *  Currency
 */
export const SET_CURRENCIES = 'SET_CURRENCIES'

/**
 *  Payments
 */
export const SET_PAYMENTS = 'SET_PAYMENTS'

/**
 *  Deposit
 */
export const SET_DEPOSIT_WALLET_ID = 'SET_DEPOSIT_WALLET_ID'
export const SET_DEPOSIT_WALLET_METHOD = 'SET_DEPOSIT_WALLET_METHOD'
export const SET_DEPOSIT_MT5_ACCOUNT = 'SET_DEPOSIT_MT5_ACCOUNT'
export const SET_DEPOSIT_MT5_WALLET = 'SET_DEPOSIT_MT5_WALLET'

/**
 *  Withdrawal
 */
export const SET_WITHDRAWAL_WALLET_ID = 'SET_WITHDRAWAL_WALLET_ID'
export const SET_WITHDRAWAL_WALLET_METHOD = 'SET_WITHDRAWAL_WALLET_METHOD'
export const SET_WITHDRAWAL_MT5_ACCOUNT = 'SET_WITHDRAWAL_MT5_ACCOUNT'
export const SET_WITHDRAWAL_MT5_WALLET = 'SET_WITHDRAWAL_MT5_WALLET'

/**
 *  Demo
 */
export const SET_DEMO_ACCOUNT = 'SET_DEMO_ACCOUNT'

/**
 *  Live
 */
export const SET_LIVE_ACCOUNT = 'SET_LIVE_ACCOUNT'

/**
 *  Verification
 */
export const SET_ACTIVE_VERIFICATION = 'SET_ACTIVE_VERIFICATION'
export const SET_ID_FRONT = 'SET_ID_FRONT'
export const SET_ID_BACK = 'SET_ID_BACK'
export const SET_ADDRESS_FRONT = 'SET_ADDRESS_FRONT'
export const SET_ADDRESS_BACK = 'SET_ADDRESS_BACK'

/**
 *  Dashboard
 */
export const SET_DASHBOARD_ACCOUNT = 'SET_DASHBOARD_ACCOUNT'

/**
 *  Promotion
 */
export const SET_PROMOTIONS = 'SET_PROMOTIONS'

/**
 *  Announcement
 */
export const SET_ANNOUNCEMENTS = 'SET_ANNOUNCEMENTS'
export const SET_ANNOUNCEMENTS_INIT = 'SET_ANNOUNCEMENTS_INIT'
