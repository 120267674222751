import React, { useState, useEffect, Fragment } from 'react'
import { Input, Label } from 'reactstrap'
import moment from 'moment'
const HistoryFilters = ({ types, handleFilter }) => {
  const [typeFilter, setTypeFilter] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useEffect(() => {
    // console.log(typeFilter, fromDate, toDate)
    let filterObject = {}

    if (typeFilter != null && typeFilter >= 0) {
      filterObject['txn_type'] = typeFilter
    }
    if (fromDate != null) {
      filterObject['from_date'] = fromDate
    }
    if (toDate != null) {
      filterObject['to_date'] = toDate
    }

    handleFilter(filterObject)
    // eslint-disable-next-line
  }, [typeFilter, fromDate, toDate])

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">Type</h6>
        <div className="checkbox-animated mt-0">
          {types.map((type, index) => {
            return (
              <Label className="d-block" key={type.id}>
                <Input
                  className="radio_animated"
                  id={type.id}
                  type="radio"
                  name="type"
                  onClick={(e) => setTypeFilter(type.id)}
                />
                {type.name}
              </Label>
            )
          })}
        </div>
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">From</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={fromDate}
          onChange={(e) => {
            setFromDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>

      <div className="product-filter">
        <h6 className="f-w-600">To</h6>
        <Input
          className="form-control digits"
          type="date"
          defaultValue={toDate}
          onChange={(e) => {
            setToDate(moment(e.currentTarget.value).utc().format())
          }}
        />
      </div>

      {/* <div className="product-filter slider-product">
        <h6 className="f-w-600">Colors</h6>
        <div className="color-selector">
          <ul>
            {colors.map((color, i) => {
              return (
                <li
                  className={color}
                  key={i}
                  title={color}
                  onClick={(e) => colorHandle(e, color)}
                ></li>
              )
            })}
          </ul>
        </div>
      </div>

      <div>
        <div className="product-filter pb-0">
          <h6 className="f-w-600">Price</h6>
          <InputRange
            maxValue={prices.max}
            minValue={prices.min}
            value={value}
            onChange={(value) => dispatch(filterPrice({ value }))}
          />
        </div>
      </div> */}
    </Fragment>
  )
}

export default HistoryFilters
