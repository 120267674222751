import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import EditProfile from './edit_profile'
import NewProfile from './new_profile'
import { Card, CardBody, Spinner } from 'reactstrap'

const UserDetail = () => {
  // const profile = useSelector((state) => state.user.profile)

  // // edit
  // if (profile != null && !_.isEmpty(profile)) {
  //   return <EditProfile />
  // }

  // // new
  // if (profile != null && _.isEmpty(profile)) {
  //   return <NewProfile />
  // }
  return <EditProfile />
  // loading
  return (
    <Card>
      <CardBody style={{ textAlign: 'center' }}>
        <Spinner />
      </CardBody>
    </Card>
  )
}

export default UserDetail
