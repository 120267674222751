import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Button, Media, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import styled from 'styled-components'

const LoginButton = styled.button`
  margin-right: 37px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
`

const SignInForm = ({ logo }) => {
  const { register, handleSubmit, errors } = useForm()
  const [loginError, setLoginError] = useState(null)

  const submitAuth = async (data) => {
    try {
      const result = await AuthService.login({
        email: data.email,
        password: data.password
      })

      // console.log(result)

      if (result.success) {
        localStorage.setItem('token', result.data.jwt.token)
        localStorage.setItem('user', JSON.stringify(result.data.user))
        // console.log(result.data.user)

        // const updatedUser = await UserService.getMyself()
        // if (updatedUser?.verification?.status === 1) {
        //   window.location.reload()
        // } else {
        // redirect to profile page if not verified
        window.location.href = '/dashboard'
        // }
      }
    } catch (error) {
      console.log(error)
      if (error.message === 'E_INVALID_AUTH_PASSWORD' || error.message === 'E_INVALID_AUTH_UID') {
        setLoginError('Invalid credential')
      } else {
        setLoginError(error.message ?? 'Please try again later')
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className="mb-3" style={{ textAlign: 'center' }}>
            {logo && <img src={logo} alt="logo" style={{ maxHeight: 175, maxWidth: 175 }} />}
          </div>

          <Form
            id="login-form"
            className="theme-form cont"
            onSubmit={handleSubmit(submitAuth)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '40px',
              border: '1px solid #cccccc',
              width: '600px'
            }}
          >
            <h4 style={{ color: '#512367' }}>Login</h4>
            {/* <p style={{ color: '#767676' }}>Enter your email & password to login</p> */}

            <Label className="col-form-label pt-0">Email Address</Label>
            <FormGroup
              style={{ display: 'flex', backgroundColor: 'transparent', marginBottom: '10px' }}
            >
              <Input
                id="input-form"
                className="form-control"
                placeholder="Email Address"
                type="text"
                required=""
                name="email"
                innerRef={register({ required: true })}
                style={{ backgroundColor: '#f8f8f8' }}
              />
              {/* <img width='30px' height="30px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiIGNsYXNzPSIiPjxnPjxsaW5rIHR5cGU9InRleHQvY3NzIiByZWw9InN0eWxlc2hlZXQiIGlkPSJkYXJrLW1vZGUtZ2VuZXJhbC1saW5rIj48L2xpbms+PGxpbmsgdHlwZT0idGV4dC9jc3MiIHJlbD0ic3R5bGVzaGVldCIgaWQ9ImRhcmstbW9kZS1jdXN0b20tbGluayI+PC9saW5rPjxzdHlsZSBsYW5nPSJlbiIgdHlwZT0idGV4dC9jc3MiIGlkPSJkYXJrLW1vZGUtY3VzdG9tLXN0eWxlIj48L3N0eWxlPjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0ibTQzMS45NjQgNDM1LjMzM2MtLjkyMS01OC45OTQtMTkuMy0xMTIuNjM2LTUxLjk3Ny0xNTEuNDc0LTMyLjQ4Ny0zOC42MDEtNzYuNTE1LTU5Ljg1OS0xMjMuOTg3LTU5Ljg1OXMtOTEuNSAyMS4yNTgtMTIzLjk4NyA1OS44NTljLTMyLjY0NiAzOC43OTctNTEuMDEzIDkyLjM2NC01MS45NzMgMTUxLjI4NSAxOC40NiA5LjI0NyA5NC44NSA0NC44NTYgMTc1Ljk2IDQ0Ljg1NiA4Ny43MDggMCAxNTguODQ1LTM1LjQgMTc1Ljk2NC00NC42Njd6IiBmaWxsPSIjZTliODM4IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L3BhdGg+PGNpcmNsZSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGN4PSIyNTYiIGN5PSIxMjAiIHI9Ijg4IiBmaWxsPSIjZTliODM4IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIiBjbGFzcz0iIj48L2NpcmNsZT48L2c+PC9zdmc+" /> */}
            </FormGroup>
            <span style={{ color: 'red' }}>{errors.email && 'Email is required'}</span>

            <Label className="col-form-label">Password</Label>
            <FormGroup style={{ display: 'flex', marginBottom: '15px' }}>
              <Input
                id="input-form"
                className="form-control"
                placeholder="Password"
                type="password"
                required=""
                name="password"
                innerRef={register({ required: true })}
                style={{ backgroundColor: '#f8f8f8' }}
              />
              {/* <img width="30px" height="30px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48bGluayB0eXBlPSJ0ZXh0L2NzcyIgcmVsPSJzdHlsZXNoZWV0IiBpZD0iZGFyay1tb2RlLWdlbmVyYWwtbGluayI+PC9saW5rPjxsaW5rIHR5cGU9InRleHQvY3NzIiByZWw9InN0eWxlc2hlZXQiIGlkPSJkYXJrLW1vZGUtY3VzdG9tLWxpbmsiPjwvbGluaz48c3R5bGUgbGFuZz0iZW4iIHR5cGU9InRleHQvY3NzIiBpZD0iZGFyay1tb2RlLWN1c3RvbS1zdHlsZSI+PC9zdHlsZT48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xOC43NSA5aC0uNzV2LTNjMC0zLjMwOS0yLjY5MS02LTYtNnMtNiAyLjY5MS02IDZ2M2gtLjc1Yy0xLjI0IDAtMi4yNSAxLjAwOS0yLjI1IDIuMjV2MTAuNWMwIDEuMjQxIDEuMDEgMi4yNSAyLjI1IDIuMjVoMTMuNWMxLjI0IDAgMi4yNS0xLjAwOSAyLjI1LTIuMjV2LTEwLjVjMC0xLjI0MS0xLjAxLTIuMjUtMi4yNS0yLjI1em0tMTAuNzUtM2MwLTIuMjA2IDEuNzk0LTQgNC00czQgMS43OTQgNCA0djNoLTh6bTUgMTAuNzIydjIuMjc4YzAgLjU1Mi0uNDQ3IDEtMSAxcy0xLS40NDgtMS0xdi0yLjI3OGMtLjU5NS0uMzQ3LTEtLjk4NS0xLTEuNzIyIDAtMS4xMDMuODk3LTIgMi0yczIgLjg5NyAyIDJjMCAuNzM3LS40MDUgMS4zNzUtMSAxLjcyMnoiIGZpbGw9IiNlOWI4MzgiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9zdmc+" /> */}
            </FormGroup>
            <span style={{ color: 'red' }}>{errors.password && 'Password is required'}</span>

            {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>}
            <FormGroup className=" mb-0" style={{ textAlign: 'right' }}>
              <Link to={`${process.env.PUBLIC_URL}/forgot_password`} href="#javascript">
                <p style={{ color: '#512367', textAlign: 'right' }}>Forgot password?</p>
              </Link>
              <LoginButton className="w-100" color="primary" style={{ backgroundColor: '#512367' }}>
                Login
              </LoginButton>
            </FormGroup>
            <div style={{ padding: 10 }} />

            {/* <p style={{ color: '#898989' }}>Don't have an account?
              <Link to={`${process.env.PUBLIC_URL}/sign_up`} href="#javascript">
              <span className="ml-1" style={{ color: '#f9b700' }}>Create account</span>
            </Link>
          </p> */}
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default SignInForm
