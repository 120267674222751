import {
  Home,
  Monitor,
  User,
  DollarSign,
  Download,
  Phone,
  Navigation,
  Navigation2,
  LogOut,
  Heart
} from 'react-feather'
import Auth from '../../network/services/auth'

export const MENUITEMS = [
  // {
  //   menuTitle: 'Hot',
  //   menuContent: 'Event',
  //   type: 'container'
  // },
  // {
  //   title: 'Promotion',
  //   icon: Heart,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/promotion`
  // },
  // {
  //   menuTitle: 'Main',
  //   menuContent: 'Funding',
  //   type: 'container'
  // },
  {
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/dashboard`
  },
  {
    title: 'Porfolio',
    icon: DollarSign,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/portfolio`
  },
  // {
  //   title: 'Partner Room',
  //   icon: User,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/partner`,
  //   verified: true
  // },
  // {
  //   title: "Risk Management",
  //   icon: AlertTriangle,
  //   type: "link",
  //   path: `${process.env.PUBLIC_URL}/risk`,
  // },
  // {
  //   title: 'Live Monitoring',
  //   icon: Monitor,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/monitoring`
  // },
  // {
  //   title: 'Trading Platforms',
  //   icon: Download,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/platform`
  // },
  // {
  //   title: 'Customer Support',
  //   icon: Phone,
  //   type: 'link',
  //   path: `${process.env.PUBLIC_URL}/support`
  // },
  {
    title: 'Account',
    icon: User,
    type: 'link',
    path: `${process.env.PUBLIC_URL}/account`,
    mobile: true
  },
  {
    title: 'Logout',
    icon: LogOut,
    type: 'action',
    action: () => Auth.logout(),
    mobile: true
  }
]
