import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { Row, Col, Card, CardHeader, Input } from 'reactstrap'

import TransactionService from '../../../../network/services/transaction'
import HistoryFilters from './filters'

const columns = [
  {
    name: 'Txn.No',
    selector: 'txn_id',
    width: '200px'
    // sortable: true
  },
  {
    name: 'Date',
    selector: 'created_at',
    sortable: true,
    width: '200px',
    format: (row) => {
      return moment(row.created_at).format('yyyy-MM-DD hh:mmA')
    }
  },
  {
    name: 'Type',
    selector: 'txn_type',
    sortable: true,
    format: (row) => {
      switch (row.txn_type) {
        case 0:
          return 'Credit'
        case 1:
          return 'Debit'
        default:
          return '-'
      }
    }
  },
  {
    name: 'Currency',
    selector: 'currency_unit',
    sortable: true
  },
  {
    name: 'WOTA Token',
    selector: 'amount',
    sortable: true,
    format: (row) => {
      return row.debit_amount > 0 ? row.debit_amount : row.credit_amount
    }
  },
  // {
  //   name: 'WOTA Rate',
  //   selector: 'currency_rate'
  // },
  {
    name: 'USD Value',
    selector: 'base_price'
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    format: (row) => {
      switch (row.status) {
        case 0:
          return 'Pending'
        case 1:
          return 'Success'
        case 2:
          return 'Failed'
        case 3:
          return 'Rejected'
        default:
          return '-'
      }
    }
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    width: '200px'
  }
]

const types = [
  {
    id: -1,
    name: 'All'
  },
  {
    id: 0,
    name: 'Deposit'
  },
  {
    id: 1,
    name: 'Withdraw'
  }
  // {
  //   id: 2,
  //   name: 'Transfer'
  // }
]

const HistoryTable = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [sidebaron, setSidebaron] = useState(true)
  const [filters, setFilters] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const wrapperRef = useRef()

  const handlePageChange = async (page) => {
    setIsBusy(true)
    const result = await TransactionService.getAll({ page: page, filters: filters })
    setData(result.private_transactions.data)
    setTotal(result.private_transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const handleFilter = async () => {
    setIsBusy(true)
    const result = await TransactionService.getAll({
      filters: filters
    })
    setData(result.private_transactions.data)
    setTotal(result.private_transactions?.meta?.total ?? 0)
    setIsBusy(false)
  }

  const init = async () => {
    try {
      setIsBusy(true)
      const result = await TransactionService.getAll()
      // console.log(result.private_transactions)
      setData(result.private_transactions?.data ?? [])
      setTotal(result.private_transactions?.meta?.total ?? 0)
      setIsBusy(false)
    } catch (error) {
      // console.log(error)
      setIsBusy(false)
    }
  }

  const onClickFilter = () => {
    if (sidebaron) {
      setSidebaron(false)
      wrapperRef.current.classList.add('sidebaron')
    } else {
      setSidebaron(true)
      wrapperRef.current.classList.remove('sidebaron')
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    setFilters({
      txn_id: keyword
    })
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [filters])

  return (
    <div className="product-wrapper" ref={wrapperRef}>
      <div className="product-grid" style={{ minHeight: '50vh' }}>
        <Row>
          <Col xl="3">
            <div className="product-sidebar">
              <div className="filter-section">
                <Card className="m-0">
                  <CardHeader>
                    <h6 className="mb-0 f-w-600">
                      Filters
                      <span className="pull-right">
                        <i className="fa fa-chevron-down toggle-data" onClick={onClickFilter}></i>
                      </span>
                    </h6>
                  </CardHeader>
                  <div className="left-filter">
                    <div style={{ padding: 16 }} />
                    <HistoryFilters types={types} handleFilter={setFilters} />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
          <Col xl="9" sm="12">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Input
                className="form-control"
                type="text"
                placeholder="Search Txn"
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <div className="product-wrapper-grid" style={{ marginTop: 16 }}>
          <DataTable
            noHeader
            data={data}
            columns={columns}
            striped={true}
            center={true}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationServer
            paginationTotalRows={total}
            onChangePage={handlePageChange}
            progressPending={isBusy}
          />
        </div>
      </div>
    </div>
  )
}

export default HistoryTable
