import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Breadcrumb from '../../layout/breadcrumb'
import History from './history'

const tabs = {
  // deposit: 'Deposit',
  // withdrawal: 'Withdrawal',
  // transfer: 'Wallet Transfer',
  history: 'Portfolio History'
  // bonus: 'Manage Bonus'
}

const Financial = () => {
  const [activeTab, setActiveTab] = useState('history')
  const [refresh, setRefresh] = useState(null)
  let location = useLocation()
  let history = useHistory()

  useEffect(() => {
    if (!isEmpty(location.hash)) {
      setActiveTab(location.hash.replace('#', ''))
    } else {
      setActiveTab('history')
    }
  }, [location])

  useEffect(() => {
    setRefresh(Math.random())
  }, [activeTab])

  const setTab = (val) => {
    setRefresh(Math.random())
    history.push('#' + val)
  }

  return (
    <>
      <Breadcrumb parent="Portfolio" title={tabs[activeTab]} />
      <Container>
        {/* <Row>
          <Col sm="12" lg="8" xl="9" className="project-list">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab"> */}
        {/* <NavItem>
                      <NavLink
                        className={activeTab === 'deposit' ? 'active' : ''}
                        onClick={() => setTab('deposit')}
                      >
                        <i className="fa fa-bank"></i>
                        Deposit
                      </NavLink>
                    </NavItem> */}
        {/* <NavItem>
                      <NavLink
                        className={activeTab === 'withdrawal' ? 'active' : ''}
                        onClick={() => setTab('withdrawal')}
                      >
                        <i className="fa fa-money"></i>
                        Withdrawal
                      </NavLink>
                    </NavItem> */}
        {/* <NavItem>
                      <NavLink
                        className={activeTab === 'transfer' ? 'active' : ''}
                        onClick={() => setTab('transfer')}
                      >
                        <i className="fa fa-exchange"></i>
                        Transfer
                      </NavLink>
                    </NavItem> */}
        {/* <NavItem>
                      <NavLink
                        className={activeTab === 'history' ? 'active' : ''}
                        onClick={() => setTab('history')}
                      >
                        <i className="fa fa-history"></i>
                        History
                      </NavLink>
                    </NavItem> */}
        {/* <NavItem>
                      <NavLink
                        className={activeTab === 'bonus' ? 'active' : ''}
                        onClick={() => setTab('bonus')}
                      >
                        <i className="fa fa-percent"></i>
                        Bonus
                      </NavLink>
                    </NavItem> */}
        {/* </Nav>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col sm="12" lg="18" xl="24">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="history">
                <History refresh={refresh} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Financial
