import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, ListGroup, ListGroupItem, Input, FormText } from 'reactstrap'
import styled from 'styled-components'

const MobileGap = styled.div`
  padding: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`

const AddressUpload = () => {
  const dispatch = useDispatch()
  const addressFront = useSelector((state) => state.verification.addressFront)

  return (
    <>
      <Row>
        <Col md="12">
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              Must be dated not older than <strong>six months</strong> at the time of verification
            </ListGroupItem>
            <ListGroupItem>
              Document must show residence address, name and match registration details
            </ListGroupItem>
            <ListGroupItem>
              All information and details must be clearly visible (financial information can be
              hidden)
            </ListGroupItem>
            <ListGroupItem>Must be presented in full</ListGroupItem>
            <ListGroupItem>
              Must be statement that including address
            </ListGroupItem>
            <ListGroupItem>The edges of documents should not be cut off</ListGroupItem>
          </ListGroup>
        </Col>
        {/* <Col md="6">
          <img src="/address.jpg" alt="address" style={{ maxWidth: '100%' }} />
        </Col> */}
      </Row>

      <hr />
      <p>Upload your address proof</p>

      <Row>
        <Col>
          <FormText color="muted">Address</FormText>
          <Input
            type="file"
            name="file"
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e) => {
              dispatch({
                type: 'SET_ADDRESS_FRONT',
                addressFront: { file: e?.currentTarget?.files[0] }
              })
            }}
          />
          <FormText color="muted">.jpg, .png, pdf, max 10 MB</FormText>
          <span style={{ color: 'red' }}>{addressFront?.error != null && addressFront.error}</span>
          <MobileGap />
        </Col>
      </Row>
    </>
  )
}

export default AddressUpload
