import React from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import HistoryTable from './table'

const WalletHistory = ({ setOption }) => {
  return (
    <>
      <Card>
        <CardBody>
          <HistoryTable />
        </CardBody>
      </Card>

      {/* <Button
        color="primary"
        onClick={() => {
          setOption(null);
        }}
      >
        Back
      </Button> */}
    </>
  )
}

export default WalletHistory
