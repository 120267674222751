import React from 'react'
import { Card, CardHeader, CardBody, Col, Row, Button, Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

const CustomDiv = styled.div`
  img {
    height: 100%;
    width: 100%;
  }
`

const AnnouncementModal = () => {
  const announcements = useSelector((state) => state.announcement.announcements)
  // console.log(announcements)
  return (
    <div>
      {announcements != null ? (
        announcements?.length > 0 ? (
          announcements.map((announcement) => {
            return (
              <CustomDiv
                style={{
                  img: {
                    height: '100%',
                    width: '100%'
                  }
                }}
              >
                <h5 className="text-center">{announcement?.title}</h5>
                <ReactMarkdown children={announcement?.content} />
                <div style={{ paddingBottom: '10px' }} />
              </CustomDiv>
            )
          })
        ) : (
          <p>No record found</p>
        )
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      )}
      {/* 
      <div style={{ textAlign: 'center' }}>
        <h5>How it works?</h5>
        <Row>
          <Col>
            <img src="/refer_1.svg" alt="refer_1" style={{ height: 50, marginBottom: 12 }} />
            <p>Share your unique link with friends</p>
          </Col>
          <Col>
            <img src="/refer_2.svg" alt="refer_2" style={{ height: 50, marginBottom: 12 }} />
            <p>Your friends become traders</p>
          </Col>
          <Col>
            <img src="/refer_3.svg" alt="refer_3" style={{ height: 50, marginBottom: 12 }} />
            <p>You get rewarded for their every trade</p>
          </Col>
        </Row>
      </div> */}
    </div>
  )
}

export default AnnouncementModal
