import React, { useState, useEffect } from 'react'
import { Container, Spinner, CardBody, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import SweetAlert from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import _ from 'lodash'

import { Link } from 'react-router-dom'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'
import SettingService from '../../network/services/setting'

import styled from 'styled-components'
import 'animate.css'

const SigninButton = styled.button`
  background-color: transparent;
  border: 1px #f9b700 solid;
  color: #f9b700;
  border-radius: 10px;
  width: 25%;
`

const ResponsiveDiv = styled.div``

const SignUpForm = () => {
  const [logo, setLogo] = useState(null)
  const init = async () => {
    try {
      const result = await SettingService.getAll()
      // console.log(result)
      if (result.settings) {
        let logo2 = result.settings.find((s) => {
          return s.handle === 'crm_logo_url'
        })

        setLogo(logo2 ?? {})
      } else {
        setLogo({})
      }
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  const { control, register, handleSubmit, errors, reset, watch, clearErrors, setError } = useForm()
  const watchPassword = watch('password')

  const history = useHistory()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [referralCode, setReferralCode] = useState(null)

  const [generalError, setGeneralError] = useState(null)

  const submitRegistration = async (data) => {
    try {
      let postData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: email,
        mobile: data.mobile,
        country: data.country,
        auth_code: data.auth_code,
        password: data.password,
        password_confirmation: data.confirm_password,
        referral_code: referralCode ?? data.referral_code
      }

      const result = await AuthService.register(postData)

      if (result?.user?.id) {
        resetForm()
        SweetAlert.fire({
          title: 'Register successfully!',
          icon: 'success'
        }).then(() => {
          history.push('/auth')
        })
      }
    } catch (error) {
      console.log(error)
      setGeneralError(error.message)
    }
  }

  const verifyEmail = async () => {
    if (email == null || email === '') {
      setError('email', {
        type: 'manual',
        message: 'Email is required'
      })
      return
    }

    try {
      const result = await UserService.verifyEmail({
        email: email
      })

      if (result.success) {
        const codeResult = await AuthService.sendVerification({
          email: email
        })

        if (codeResult.success) {
          setIsEmailVerified(true)
          clearErrors('email')
        } else {
          setError('email', {
            type: 'manual',
            message: codeResult.message ?? 'Please try again later'
          })
          throw codeResult
        }
      } else {
        console.log('?')
        setError('email', {
          type: 'manual',
          message: 'Email already taken'
        })
        throw result
      }
    } catch (error) {
      console.log(error)
    }
  }

  const resetForm = () => {
    reset()
    setEmail('')
    setGeneralError(null)
    setIsEmailVerified(false)
  }

  const switchSignIn = () => {
    // document.querySelector(".cont").classList.toggle("s--signup")
    history.push('/auth')
  }

  useEffect(() => {
    let referralCodeQuery = new URLSearchParams(location.search).get('referral_code')
    if (!_.isEmpty(referralCodeQuery)) {
      setReferralCode(referralCodeQuery)
    }
  }, [])

  return (
    <>
      {logo != null ? (
        <Container id="mobile_scroll" style={{ maxWidth: '100%', overflow: 'scroll' }}>
          <Row style={{ height: '100vh', backgroundColor: '#e6e6e6' }}>
            <Col
              id="signUp-col-1"
              lg="4"
              style={{
                padding: '15px',
                backgroundImage:
                  'https://wota-live-s3.s3-ap-southeast-1.amazonaws.com/loginBackground.png',
                textAlign: 'center',
                order: 1
              }}
            >
              <ResponsiveDiv>
                <div className="animate__animated animate__fadeInRight">
                  <h2 style={{ color: '#f9b700' }}>One of us?</h2>
                  <p style={{ color: 'white' }}>
                    If you already have an account, <br></br>just sign in. We've missed you!
                  </p>
                  <SigninButton onClick={switchSignIn}>Sign In</SigninButton>
                </div>
              </ResponsiveDiv>
            </Col>

            <Col id="signUp-col-2" lg="8" style={{ textAlign: 'left', backgroundColor: '#e6e6e6' }}>
              <Row style={{ justifyContent: 'center' }}>
                <div style={{ marginBottom: '40px' }}>
                  {logo?.value && (
                    <Link to={`${process.env.PUBLIC_URL}/auth`}>
                      <img
                        id="signUp-logo"
                        src={logo.value}
                        alt="logo"
                        style={{
                          maxHeight: 175,
                          maxWidth: 175
                        }}
                      />
                    </Link>
                  )}
                </div>
              </Row>
              <Form
                id="registerForm"
                className="theme-form"
                onSubmit={(e) => {
                  e.preventDefault()
                }}
                // onSubmit={handleSubmit(submitRegistration)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <h4>REGISTER</h4>
                {!isEmailVerified ? (
                  <p style={{ color: '#858585' }}>
                    Please enter your email to receive the verification code{' '}
                  </p>
                ) : (
                  ''
                )}

                <FormGroup>
                  <Input
                    className="form-control borderInput"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                    disabled={isEmailVerified ? true : false}
                    innerRef={register({ required: 'Email is required' })}
                    required
                  />
                  <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
                </FormGroup>

                {isEmailVerified ? (
                  <>
                    <Row form>
                      <Col md="12">
                        {isEmailVerified ? (
                          <span style={{ color: 'green', fontSize: '11px' }}>
                            {`Please check ${email} and your spam mail or junk box for verification code`}
                            <br></br>
                          </span>
                        ) : (
                          ''
                        )}
                        <FormGroup>
                          <Input
                            className="form-control borderInput"
                            type="text"
                            placeholder="Verification Code"
                            name="auth_code"
                            innerRef={register({
                              required: 'Verification Code is required'
                            })}
                          />
                          <span style={{ color: 'red' }}>
                            {errors.auth_code && errors.auth_code.message}
                          </span>
                        </FormGroup>

                        {isEmailVerified ? (
                          <span style={{ color: 'red', fontSize: '11px' }}>
                            {`Please enter your First Name and Last Name follow by IC / Passport `}
                          </span>
                        ) : (
                          ''
                        )}
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Input
                            className="form-control borderInput"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            innerRef={register({
                              required: 'First name is required'
                            })}
                          />
                          <span style={{ color: 'red' }}>
                            {errors.first_name && errors.first_name.message}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            className="form-control borderInput"
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            innerRef={register({ required: 'Last name is required' })}
                          />
                          <span style={{ color: 'red' }}>
                            {errors.last_name && errors.last_name.message}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup className="borderInput rounded">
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: 'Mobile is required' }}
                        as={
                          <PhoneInput
                            country={'my'}
                            className="form-control "
                            inputStyle={{ width: '100%' }}
                            dropdownStyle={{
                              maxHeight: 100
                            }}
                          />
                        }
                      />
                      {/* <PhoneInput value={mobile}  /> */}
                      <span style={{ color: 'red' }}>{errors.mobile && errors.mobile.message}</span>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        className="form-control borderInput"
                        type="select"
                        name="country"
                        placeholder="Country"
                        innerRef={register({ required: true })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </Input>
                      <span style={{ color: 'red' }}>
                        {errors.country && 'Country is required'}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        className="form-control borderInput"
                        type="text"
                        placeholder="Referral Code"
                        name="referral_code"
                        defaultValue={referralCode}
                        innerRef={register({
                          required: false
                        })}
                        disabled={referralCode != null}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.referral_code && errors.referral_code.message}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        className="form-control borderInput"
                        type="password"
                        placeholder="Password"
                        name="password"
                        innerRef={register({
                          required: 'Password is required',
                          minLength: {
                            value: 8,
                            message: 'Password minumum length is 8'
                          }
                        })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.password && errors.password.message}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        className="form-control borderInput"
                        type="password"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        innerRef={register({
                          required: 'Confirm Password is required',
                          minLength: 8,
                          validate: (value) => {
                            return value === watchPassword || 'Password does not match'
                          }
                        })}
                      />
                      <span style={{ color: 'red' }}>
                        {errors.confirm_password && errors.confirm_password.message}
                      </span>
                    </FormGroup>

                    <Row form>
                      <Col>
                        <Button
                          className="mb-5 mt-3"
                          color="secondary btn-block"
                          onClick={() => handleSubmit(submitRegistration)()}
                        >
                          Sign Up
                        </Button>
                      </Col>
                    </Row>

                    <span style={{ color: 'red' }}>{generalError != null && generalError}</span>
                  </>
                ) : (
                  <Row form>
                    <Col>
                      <Button
                        color="secondary btn-block"
                        onClick={() => {
                          verifyEmail()
                        }}
                      >
                        Send verification code
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container
            style={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spinner />
          </Container>
        </>
      )}
    </>
  )
}

export default SignUpForm
