import { SET_PROMOTIONS } from '../actionTypes'

const initial_state = {
  promotions: []
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_PROMOTIONS:
      return { ...state, promotions: action.promotions }

    default:
      return { ...state }
  }
}
