import client from '../request'
import _ from 'lodash'

const getMyself = () => {
  return client.get('/me')
}

const createProfile = (data) => {
  return client.post('/me/profiles', data)
}

const updateProfile = (data) => {
  return client.put(`/me/users`, data)
}

const changePassword = (data) => {
  return client.post(`/auth/change_password`, data)
}

const update2fa = (data) => {
  return client.post(`/me/two_factor`, data)
}

const createBank = (data) => {
  return client.post('/me/banks', data)
}

const updateBank = (id, data) => {
  return client.put(`/me/banks/${id}`, data)
}

const verifyEmail = (data) => {
  return client.post('/users/available', data)
}

export default {
  getMyself,
  createProfile,
  updateProfile,
  createBank,
  updateBank,
  verifyEmail,
  changePassword,
  update2fa
}
