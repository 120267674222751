import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import { Grid } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash'

import { MENUITEMS } from '../../redux/sidebar/menu'

const Sidebar = (props) => {
  const dispatch = useDispatch()
  const mainMenu = useSelector((state) => state.sidebar.menuItems)
  const verification = useSelector((state) => _.last(state.user.verifications))
  const user = useSelector((state) => state.user.user)
  const partners = useSelector((state) => state.user.partners)
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    const currentUrl = window.location.pathname

    mainMenu.filter((items) => {
      if (items.path === currentUrl) {
        setNavActive(items)
      }

      if (!items.children) {
        return false
      }

      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) {
          setNavActive(subItems)
        }

        if (!subItems.children) {
          return false
        }

        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems)
            return true
          } else {
            return false
          }
        })

        return subItems
      })

      return items
    })
    // eslint-disable-next-line
  }, [])

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false
      }

      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true
      }

      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
            return true
          } else {
            return false
          }
        })
      }

      return menuItem
    })

    item.active = !item.active
    dispatch({ type: 'SET_MAIN_MENU', menuItems: MENUITEMS })
  }

  const toggleNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) {
          a.active = false
        }

        if (!a.children) {
          return false
        }

        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false
          }

          if (!b.children) {
            return false
          }

          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false
            }
          })
        })
      })
    }

    item.active = !item.active
    dispatch({ type: 'SET_MAIN_MENU', menuItems: MENUITEMS })
    if (isMobileOrTablet) {
      dispatch({ type: 'TRIGGER_SIDEBAR' })
    }
  }

  const responsiveSidebar = () => {
    dispatch({ type: 'TRIGGER_SIDEBAR' })
  }

  return (
    <>
      <header className="main-nav">
        <div className="logo-wrapper">
          <img
            className="img-fluid for-light"
            src={require('../../assets/images/logo/logo.png')}
            alt=""
            style={{
              padding: '15px 20px'
            }}
          />
          <img
            className="img-fluid for-dark"
            src={require('../../assets/images/logo/logo_dark.png')}
            alt=""
          />
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="toggle-sidebar" onClick={() => responsiveSidebar()}>
            <Grid className="status_toggle middle" id="sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
            <img
              className="img-fluid"
              src={require('../../assets/images/logo/logo-icon.png')}
              alt=""
            />
          </Link>
        </div>
        <nav>
          <div className="main-navbar">
            <div id="mainnav">
              <ul className="nav-menu custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-right">
                    <span>Back</span>
                    <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                  </div>
                </li>
                {MENUITEMS.map((menuItem, i) => (
                  <li className="dropdown" key={i}>
                    {menuItem.type === 'container' && (
                      <div className={`sidebar-title`}>
                        <div>
                          <h6 className="lan-1">{menuItem.menuTitle}</h6>
                          <p className="lan-2">{menuItem.menuContent}</p>
                        </div>
                      </div>
                    )}
                    {menuItem.type === 'sub' && (
                      <a
                        className={`nav-link menu-title ${menuItem.active ? 'active' : ''}`}
                        href="#javascript"
                        onClick={() => toggleNavActive(menuItem)}
                      >
                        <menuItem.icon />
                        <span>{props.t(menuItem.title)}</span>
                        <div className="according-menu">
                          <i className={`fa fa-angle-${menuItem.active ? 'down' : 'right'}`} />
                        </div>
                      </a>
                    )}
                    {menuItem.type === 'link' && (
                      <>
                        {menuItem.verified ? (
                          <>
                            {verification != null &&
                              verification.status === 1 &&
                              user?.role?.handle !== 'client' &&
                              partners?.length > 0 && (
                                <Link
                                  to={menuItem.path}
                                  className={`nav-link menu-title link-nav  ${
                                    menuItem.active ? 'active' : ''
                                  }`}
                                  href="#javascript"
                                  onClick={() => toggleNavActive(menuItem)}
                                >
                                  <menuItem.icon />
                                  <span>{props.t(menuItem.title)}</span>
                                  {menuItem.badge && (
                                    <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                                  )}
                                </Link>
                              )}
                          </>
                        ) : (
                          <Link
                            to={menuItem.path}
                            className={`nav-link menu-title link-nav  ${
                              menuItem.active ? 'active' : ''
                            }`}
                            href="#javascript"
                            onClick={() => toggleNavActive(menuItem)}
                          >
                            <menuItem.icon />
                            <span>{props.t(menuItem.title)}</span>
                            {menuItem.badge && (
                              <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                            )}
                          </Link>
                        )}
                      </>
                    )}
                    {menuItem.type === 'action' && (
                      <a
                        className={`nav-link menu-title link-nav ${
                          menuItem.active ? 'active' : ''
                        }`}
                        href="#javascript"
                        onClick={menuItem.action}
                      >
                        <menuItem.icon />
                        <span>{props.t(menuItem.title)}</span>
                        {menuItem.badge && (
                          <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                        )}
                      </a>
                    )}
                    {menuItem.type === 'external_link' && (
                      <a
                        href={menuItem.path}
                        className={`nav-link menu-title link-nav  ${
                          menuItem.active ? 'active' : ''
                        }`}
                        // onClick={() => toggleNavActive(menuItem)}
                      >
                        <menuItem.icon />
                        {props.t(menuItem.title)}
                        {menuItem.badge && (
                          <label className={menuItem.badge}>{menuItem.badgetxt}</label>
                        )}
                      </a>
                    )}
                    {menuItem.children && (
                      <ul
                        className="nav-submenu menu-content"
                        style={
                          menuItem.active
                            ? {
                                opacity: 1,
                                transition: 'opacity 500ms ease-in'
                              }
                            : { display: 'none' }
                        }
                      >
                        {menuItem.children.map((childrenItem, index) => {
                          return (
                            <li key={index}>
                              {childrenItem.type === 'sub' && (
                                <a
                                  className={`${childrenItem.active ? 'active' : ''}`}
                                  href="#javascript"
                                  onClick={() => toggleNavActive(childrenItem)}
                                >
                                  {props.t(childrenItem.title)}
                                  <span className="sub-arrow">
                                    <i className="fa fa-chevron-right"></i>
                                  </span>
                                  <div className="according-menu">
                                    <i
                                      className={`fa fa-angle-${
                                        childrenItem.active ? 'down' : 'right'
                                      }`}
                                    />
                                  </div>
                                </a>
                              )}

                              {childrenItem.type === 'link' && (
                                <Link
                                  to={childrenItem.path}
                                  className={`${childrenItem.active ? 'active' : ''}`}
                                  onClick={() => toggleNavActive(childrenItem)}
                                >
                                  {props.t(childrenItem.title)}
                                </Link>
                              )}

                              {childrenItem.type === 'external_link' && (
                                <a
                                  href={childrenItem.path}
                                  className={childrenItem.active ? 'active' : ''}
                                >
                                  {props.t(childrenItem.title)}
                                </a>
                              )}

                              {childrenItem.children && (
                                <ul
                                  className="nav-sub-childmenu submenu-content"
                                  style={{
                                    display: childrenItem.active ? 'block' : 'none'
                                  }}
                                >
                                  {childrenItem.children.map((childrenSubItem, key) => (
                                    <li key={key}>
                                      {childrenSubItem.type === 'link' && (
                                        <Link
                                          to={childrenSubItem.path}
                                          className={`${childrenSubItem.active ? 'active' : ''}`}
                                          onClick={() => toggleNavActive(childrenSubItem)}
                                        >
                                          {props.t(childrenSubItem.title)}
                                        </Link>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default translate(Sidebar)
