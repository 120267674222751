import React from 'react'
import { Media } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import AddressUpload from './address_upload'

const translateStatus = (status) => {
  switch (status) {
    case 0:
      return 'Pending'
    case 1:
      return 'Verified'
    case 2:
      return 'Failed'
    case 3:
      return 'Rejected'
    default:
      return '-'
  }
}

const AddressTable = ({ reupload }) => {
  const verification = useSelector((state) => _.last(state.user.verifications))

  if (verification == null || reupload) {
    return (
      <>
        <p>
          Our compliance requires that we verify your address of residence. To do so, you need to
          upload document confirming your address. This document must be issued by government
          agency, local utility company or a financial institution. Please remember that all details
          in your profile must match those on the documents you submit.
        </p>
        <AddressUpload />
      </>
    )
  }

  return (
    <div className="blog-box blog-grid text-center">
      <a href={verification.address_front} target="_blank" rel="noopener noreferrer">
        <Media className="img-fluid top-radius-blog" src={verification.address_front} alt="" />
      </a>
      <div className="blog-details-main">
        <ul className="blog-social">
          <li className="digits">{moment(verification.created_at).format('yyyy-MM-DD hh:mmA')}</li>
          <li className="digits" style={{ fontWeight: 'bold' }}>
            {translateStatus(verification.status)}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AddressTable
