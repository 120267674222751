import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import PortfolioTable from './table'
import { ResponsiveDiv, StyledRow } from '../../common/components.style'

import TransactionService from '../../../network/services/transaction'
import UserService from '../../../network/services/user'

const Wallet = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [transactions, setTransactions] = useState(null)
  const [wallet, setWallet] = useState(null)
  const init = async () => {
    const transactionResponse = await TransactionService.getAll()
    setTransactions(transactionResponse.private_transactions.data)
    const findUser = await UserService.getMyself()
    setWallet(findUser)
  }

  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Card className="card-absolute" key={user.id}>
        <CardHeader className="bg-primary">
          {wallet != null && <h6 style={{ margin: 0 }}>{`${wallet.user.email}`}</h6>}
        </CardHeader>
        <div className="card-right-full">
          <ResponsiveDiv>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/portfolio#history`)
              }}
            >
              History
            </Button>
          </ResponsiveDiv>
        </div>
        <CardBody className="text-center">
          {wallet != null && (
            <StyledRow>
              <Col sm={6} lg={4}>
                <h6>{`${wallet.total_balance} USD`}</h6>
                <p>TOTAL VALUE</p>
              </Col>
              <Col sm={6} lg={4}>
                <h6>{`${wallet.total_amount} WOTA`}</h6>
                <p>BALANCE</p>
              </Col>
              <Col sm={6} lg={4}>
                <h6>{`${wallet.total_credit_amount} WOTA`}</h6>
                <p>TOTAL CREDIT</p>
              </Col>
            </StyledRow>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {transactions != null ? (
            transactions?.length > 0 ? (
              <PortfolioTable transactions={transactions} />
            ) : (
              <h5>No recent transactions</h5>
            )
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Spinner />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default Wallet
