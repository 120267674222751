import styled from 'styled-components'
import { Row } from 'reactstrap'

const ResponsiveDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  *:not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: 575px) {
    flex-direction: column;

    button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;
    }

    div.dropdown-account {
      div.dropdown {
        width: 100%;
        margin: 0;
        display: block;

        button {
          width: 100%;
        }
      }
    }
  }
`

const StyledRow = styled(Row)`
  p {
    margin-bottom: 12px !important;
  }

  h6 {
    margin: 0;
  }
`

export { ResponsiveDiv, StyledRow }
