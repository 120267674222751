import Authentication from '../components/auth'
import Dashboard from '../components/dashboard'
import Portfolio from '../components/portfolio'
// import PaymentCallback from '../components/payment'
import CustomerSupport from '../components/support'
import SupportReply from '../components/support/reply'
import User from '../components/user'

export const routes = [
  { path: `${process.env.PUBLIC_URL}/auth`, Component: Authentication },
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: Dashboard },
  { path: `${process.env.PUBLIC_URL}/portfolio`, Component: Portfolio },
  { path: `${process.env.PUBLIC_URL}/support`, Component: CustomerSupport },
  { path: `${process.env.PUBLIC_URL}/support/:id`, Component: SupportReply },
  { path: `${process.env.PUBLIC_URL}/account`, Component: User }
  // { path: `${process.env.PUBLIC_URL}/partner`, Component: Partner },
  // { path: `${process.env.PUBLIC_URL}/risk`, Component: RiskManagement },
  // { path: `${process.env.PUBLIC_URL}/monitoring`, Component: Monitoring },
  // { path: `${process.env.PUBLIC_URL}/platform`, Component: Platform },
  // { path: `${process.env.PUBLIC_URL}/demo`, Component: DemoAccounts },
  // { path: `${process.env.PUBLIC_URL}/demo/register`, Component: DemoAccountRegistration },
  // { path: `${process.env.PUBLIC_URL}/live`, Component: LiveAccounts },
  // { path: `${process.env.PUBLIC_URL}/live/register`, Component: LiveAccountRegistration },
  // {
  //   path: `${process.env.PUBLIC_URL}/payment`,
  //   Component: PaymentCallback
  // },

  // Promotion Route
  // {
  //   path: `${process.env.PUBLIC_URL}/promotion`,
  //   Component: Promotion
  // },
  // { path: `${process.env.PUBLIC_URL}/promotion/:id`, Component: PromotionLanding }
]
