import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import styled from 'styled-components'
import UserService from '../../../network/services/user'
import { bankList } from '../../../network/constants'
import _ from 'lodash'
const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: flex-start;

    > button {
      margin-top: 8px;
    }
  }
`

const BankForm = ({ bank, setIsEditable }) => {
  const { register, handleSubmit, errors, watch, setValue, reset, formState } = useForm()
  // const { isSubmitting } = formState
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [banks, setBanks] = useState(null)
  const country = watch('country', '')

  const [accountHolder, setAcountHolder] = useState('')
  const [bankBranch, setBankBranch] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [countrySelect, setCountrySelect] = useState('')
  const [bankName, setBankName] = useState('')

  const submitUpdate = async (data) => {
    // console.log(data)

    setIsSubmitting(true)

    SweetAlert.fire({
      title: `Please ensure your bank details is correct`,
      html: `<div style={{textAlign:'left'}}>
              <p>AccountHolder : ${accountHolder || bank?.account_holder}<p>
              <p>Bank Branch : ${bankBranch || bank?.bank_branch}<p>
              <p>Account Number : ${accountNumber || bank?.account_number}<p>
              <p>Bank Code : ${bankCode || bank?.swift_code}<p>
              <p>Country : ${countrySelect}<p>
             </div>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        try {
          if (bank == null) {
            const result = await UserService.createBank(data)
            // console.log(result)

            if (result.bank) {
              reset()
              SweetAlert.fire({
                title: 'Bank created successfully!',
                text: 'Your request will be review by admin',
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          } else {
            const result = await UserService.updateBank(bank.id, data)
            // console.log(result)

            if (result.bank) {
              reset()
              SweetAlert.fire({
                title: 'Bank updated successfully!',
                icon: 'success'
              }).then(() => {
                window.location.reload()
              })
            }
          }
        } catch (error) {
          setIsSubmitting(false)
          console.log(error)
          SweetAlert.fire({
            title: error.message,
            icon: 'error'
          })
        }
      }
    })

    setIsSubmitting(false)
  }

  useEffect(() => {
    if (bank != null) {
      setValue('account_holder', bank?.account_holder)
      setValue('bank_branch', bank?.bank_branch)
      setValue('account_number', bank?.account_number)
      setValue('swift_code', bank?.swift_code)
      setValue('bank_name', bank?.bank_name)
    }
    // eslint-disable-next-line
  }, [bank])

  useEffect(() => {
    if (country != null) {
      setBanks(_.filter(bankList, ['country', country]))
    }
    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    if (bank != null) {
      setValue('account_holder', bank?.account_holder)
      setValue('bank_branch', bank?.bank_branch)
      setValue('account_number', bank?.account_number)
      setValue('swift_code', bank?.swift_code)
      setValue('bank_name', bank?.bank_name)
    }
    // eslint-disable-next-line
  }, [bank])

  return (
    <Form
      className="theme-form"
      onSubmit={handleSubmit(submitUpdate)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.75rem'
      }}
    >
      <FormGroup>
        <Label className="col-form-label pt-0">Account Holder</Label>
        <Input
          className="form-control"
          type="text"
          name="account_holder"
          innerRef={register({ required: true })}
          onChange={(e) => {
            setAcountHolder(e.target.value)
          }}
        />
        <span style={{ color: 'red' }}>{errors.account_holder && 'Name is required'}</span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">Bank Branch</Label>
        <Input
          className="form-control"
          type="text"
          name="bank_branch"
          innerRef={register({ required: true })}
          onChange={(e) => {
            setBankBranch(e.target.value)
          }}
        />
        <span style={{ color: 'red' }}>{errors.bank_branch && 'Branch is required'}</span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">Account Number</Label>
        <Input
          className="form-control"
          type="text"
          name="account_number"
          innerRef={register({ required: true })}
          onChange={(e) => {
            setAccountNumber(e.target.value)
          }}
        />
        <span style={{ color: 'red' }}>
          {errors.account_number && 'Account Number is required'}
        </span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">IFSC/SWIFT code</Label>
        <Input
          className="form-control"
          type="text"
          name="swift_code"
          innerRef={register({ required: true })}
          onChange={(e) => {
            setBankCode(e.target.value)
          }}
        />
        <span style={{ color: 'red' }}>{errors.swift_code && 'Swift Code is required'}</span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">Country</Label>
        <Input
          className="form-control borderInput"
          type="select"
          name="country"
          placeholder="Select Country"
          innerRef={register({ required: true })}
          defaultValue=""
          onChange={(e) => {
            setCountrySelect(e.target.value)
          }}
        >
          <option value="" disabled>
            Select Country
          </option>
          <option value="Afganistan">Afghanistan</option>
          <option value="Albania">Albania</option>
          <option value="Algeria">Algeria</option>
          <option value="American Samoa">American Samoa</option>
          <option value="Andorra">Andorra</option>
          <option value="Angola">Angola</option>
          <option value="Anguilla">Anguilla</option>
          <option value="Antigua & Barbuda">Antigua & Barbuda</option>
          <option value="Argentina">Argentina</option>
          <option value="Armenia">Armenia</option>
          <option value="Aruba">Aruba</option>
          <option value="Australia">Australia</option>
          <option value="Austria">Austria</option>
          <option value="Azerbaijan">Azerbaijan</option>
          <option value="Bahamas">Bahamas</option>
          <option value="Bahrain">Bahrain</option>
          <option value="Bangladesh">Bangladesh</option>
          <option value="Barbados">Barbados</option>
          <option value="Belarus">Belarus</option>
          <option value="Belgium">Belgium</option>
          <option value="Belize">Belize</option>
          <option value="Benin">Benin</option>
          <option value="Bermuda">Bermuda</option>
          <option value="Bhutan">Bhutan</option>
          <option value="Bolivia">Bolivia</option>
          <option value="Bonaire">Bonaire</option>
          <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
          <option value="Botswana">Botswana</option>
          <option value="Brazil">Brazil</option>
          <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
          <option value="Brunei">Brunei</option>
          <option value="Bulgaria">Bulgaria</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cambodia">Cambodia</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Canada">Canada</option>
          <option value="Canary Islands">Canary Islands</option>
          <option value="Cape Verde">Cape Verde</option>
          <option value="Cayman Islands">Cayman Islands</option>
          <option value="Central African Republic">Central African Republic</option>
          <option value="Chad">Chad</option>
          <option value="Channel Islands">Channel Islands</option>
          <option value="Chile">Chile</option>
          <option value="China">China</option>
          <option value="Christmas Island">Christmas Island</option>
          <option value="Cocos Island">Cocos Island</option>
          <option value="Colombia">Colombia</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Cook Islands">Cook Islands</option>
          <option value="Costa Rica">Costa Rica</option>
          <option value="Cote DIvoire">Cote DIvoire</option>
          <option value="Croatia">Croatia</option>
          <option value="Cuba">Cuba</option>
          <option value="Curaco">Curacao</option>
          <option value="Cyprus">Cyprus</option>
          <option value="Czech Republic">Czech Republic</option>
          <option value="Denmark">Denmark</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Dominica">Dominica</option>
          <option value="Dominican Republic">Dominican Republic</option>
          <option value="East Timor">East Timor</option>
          <option value="Ecuador">Ecuador</option>
          <option value="Egypt">Egypt</option>
          <option value="El Salvador">El Salvador</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Estonia">Estonia</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Falkland Islands">Falkland Islands</option>
          <option value="Faroe Islands">Faroe Islands</option>
          <option value="Fiji">Fiji</option>
          <option value="Finland">Finland</option>
          <option value="France">France</option>
          <option value="French Guiana">French Guiana</option>
          <option value="French Polynesia">French Polynesia</option>
          <option value="French Southern Ter">French Southern Ter</option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Georgia">Georgia</option>
          <option value="Germany">Germany</option>
          <option value="Ghana">Ghana</option>
          <option value="Gibraltar">Gibraltar</option>
          <option value="Great Britain">Great Britain</option>
          <option value="Greece">Greece</option>
          <option value="Greenland">Greenland</option>
          <option value="Grenada">Grenada</option>
          <option value="Guadeloupe">Guadeloupe</option>
          <option value="Guam">Guam</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Guinea">Guinea</option>
          <option value="Guyana">Guyana</option>
          <option value="Haiti">Haiti</option>
          <option value="Hawaii">Hawaii</option>
          <option value="Honduras">Honduras</option>
          <option value="Hong Kong">Hong Kong</option>
          <option value="Hungary">Hungary</option>
          <option value="Iceland">Iceland</option>
          <option value="Indonesia">Indonesia</option>
          <option value="India">India</option>
          <option value="Iran">Iran</option>
          <option value="Iraq">Iraq</option>
          <option value="Ireland">Ireland</option>
          <option value="Isle of Man">Isle of Man</option>
          <option value="Israel">Israel</option>
          <option value="Italy">Italy</option>
          <option value="Jamaica">Jamaica</option>
          <option value="Japan">Japan</option>
          <option value="Jordan">Jordan</option>
          <option value="Kazakhstan">Kazakhstan</option>
          <option value="Kenya">Kenya</option>
          <option value="Kiribati">Kiribati</option>
          <option value="Korea North">Korea North</option>
          <option value="Korea Sout">Korea South</option>
          <option value="Kuwait">Kuwait</option>
          <option value="Kyrgyzstan">Kyrgyzstan</option>
          <option value="Laos">Laos</option>
          <option value="Latvia">Latvia</option>
          <option value="Lebanon">Lebanon</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libya">Libya</option>
          <option value="Liechtenstein">Liechtenstein</option>
          <option value="Lithuania">Lithuania</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Macau">Macau</option>
          <option value="Macedonia">Macedonia</option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Malawi">Malawi</option>
          <option value="Maldives">Maldives</option>
          <option value="Mali">Mali</option>
          <option value="Malta">Malta</option>
          <option value="Marshall Islands">Marshall Islands</option>
          <option value="Martinique">Martinique</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Mayotte">Mayotte</option>
          <option value="Mexico">Mexico</option>
          <option value="Midway Islands">Midway Islands</option>
          <option value="Moldova">Moldova</option>
          <option value="Monaco">Monaco</option>
          <option value="Mongolia">Mongolia</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Myanmar">Myanmar</option>
          <option value="Nambia">Nambia</option>
          <option value="Nauru">Nauru</option>
          <option value="Nepal">Nepal</option>
          <option value="Netherland Antilles">Netherland Antilles</option>
          <option value="Netherlands">Netherlands (Holland, Europe)</option>
          <option value="Nevis">Nevis</option>
          <option value="New Caledonia">New Caledonia</option>
          <option value="New Zealand">New Zealand</option>
          <option value="Nicaragua">Nicaragua</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Niue">Niue</option>
          <option value="Norfolk Island">Norfolk Island</option>
          <option value="Norway">Norway</option>
          <option value="Oman">Oman</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Palau Island">Palau Island</option>
          <option value="Palestine">Palestine</option>
          <option value="Panama">Panama</option>
          <option value="Papua New Guinea">Papua New Guinea</option>
          <option value="Paraguay">Paraguay</option>
          <option value="Peru">Peru</option>
          <option value="Phillipines">Philippines</option>
          <option value="Pitcairn Island">Pitcairn Island</option>
          <option value="Poland">Poland</option>
          <option value="Portugal">Portugal</option>
          <option value="Puerto Rico">Puerto Rico</option>
          <option value="Qatar">Qatar</option>
          <option value="Republic of Montenegro">Republic of Montenegro</option>
          <option value="Republic of Serbia">Republic of Serbia</option>
          <option value="Reunion">Reunion</option>
          <option value="Romania">Romania</option>
          <option value="Russia">Russia</option>
          <option value="Rwanda">Rwanda</option>
          <option value="St Barthelemy">St Barthelemy</option>
          <option value="St Eustatius">St Eustatius</option>
          <option value="St Helena">St Helena</option>
          <option value="St Kitts-Nevis">St Kitts-Nevis</option>
          <option value="St Lucia">St Lucia</option>
          <option value="St Maarten">St Maarten</option>
          <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
          <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
          <option value="Saipan">Saipan</option>
          <option value="Samoa">Samoa</option>
          <option value="Samoa American">Samoa American</option>
          <option value="San Marino">San Marino</option>
          <option value="Sao Tome & Principe">Sao Tome & Principe</option>
          <option value="Saudi Arabia">Saudi Arabia</option>
          <option value="Senegal">Senegal</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Singapore">Singapore</option>
          <option value="Slovakia">Slovakia</option>
          <option value="Slovenia">Slovenia</option>
          <option value="Solomon Islands">Solomon Islands</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="Spain">Spain</option>
          <option value="Sri Lanka">Sri Lanka</option>
          <option value="Sudan">Sudan</option>
          <option value="Suriname">Suriname</option>
          <option value="Swaziland">Swaziland</option>
          <option value="Sweden">Sweden</option>
          <option value="Switzerland">Switzerland</option>
          <option value="Syria">Syria</option>
          <option value="Tahiti">Tahiti</option>
          <option value="Taiwan">Taiwan</option>
          <option value="Tajikistan">Tajikistan</option>
          <option value="Tanzania">Tanzania</option>
          <option value="Thailand">Thailand</option>
          <option value="Togo">Togo</option>
          <option value="Tokelau">Tokelau</option>
          <option value="Tonga">Tonga</option>
          <option value="Trinidad & Tobago">Trinidad & Tobago</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Turkey">Turkey</option>
          <option value="Turkmenistan">Turkmenistan</option>
          <option value="Turks & Caicos Is">Turks & Caicos Is</option>
          <option value="Tuvalu">Tuvalu</option>
          <option value="Uganda">Uganda</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="Ukraine">Ukraine</option>
          <option value="United Arab Erimates">United Arab Emirates</option>
          <option value="United States of America">United States of America</option>
          <option value="Uraguay">Uruguay</option>
          <option value="Uzbekistan">Uzbekistan</option>
          <option value="Vanuatu">Vanuatu</option>
          <option value="Vatican City State">Vatican City State</option>
          <option value="Venezuela">Venezuela</option>
          <option value="Vietnam">Vietnam</option>
          <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
          <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
          <option value="Wake Island">Wake Island</option>
          <option value="Wallis & Futana Is">Wallis & Futana Is</option>
          <option value="Yemen">Yemen</option>
          <option value="Zaire">Zaire</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </Input>
        <span style={{ color: 'red' }}>{errors.country && 'Country is required'}</span>
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label pt-0">Bank Name</Label>
        {banks != null && banks.length > 0 ? (
          <>
            <Input
              className="form-control"
              type="select"
              name="bank_name"
              innerRef={register({ required: true })}
              onChange={(e) => {
                setBankName(e.currentTarget.value)
              }}
            >
              {banks.map((item) => {
                return (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                )
              })}
            </Input>
            <span style={{ color: 'red' }}>{errors.bank_name && 'Bank Name is required'}</span>
          </>
        ) : (
          <>
            <Input
              className="form-control"
              type="text"
              name="bank_name"
              innerRef={register({ required: true })}
              onChange={(e) => {
                setBankName(e.currentTarget.value)
              }}
            ></Input>
            <span style={{ color: 'red' }}>{errors.bank_name && 'Bank Name is required'}</span>
          </>
        )}
      </FormGroup>

      <StyledRow>
        {bank != null && <Button onClick={() => setIsEditable(false)}>Cancel</Button>}
        <Button
          color="primary btn-block"
          type="submit"
          style={{ maxWidth: 150 }}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </StyledRow>
    </Form>
  )
}

export default BankForm
