import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'

import UserDetail from './details'
import BankDetail from './bank'
import Verification from './verifications'
import ChangePassword from './password'
import TwoFactorAuthentication from './authentication'
import Breadcrumb from '../../layout/breadcrumb'

import UserService from '../../network/services/user'

const tabs = {
  details: 'My Details',
  // bank: 'Bank Details',
  // verification: 'Verification',
  password: 'Change Password'
  // authentication: 'Two-Factor Authentication'
}

const User = () => {
  let location = useLocation()
  let history = useHistory()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('details')

  const init = async () => {
    const result = await UserService.getMyself()
    // console.log(result)

    dispatch({ type: 'SET_USER', user: result.user })
    dispatch({ type: 'SET_PROFILE', profile: result.profile ?? {} })

    // if (result.partners) {
    //   dispatch({ type: 'SET_AGENT', partners: result.partners })
    // }

    // if (result.bank) {
    //   dispatch({ type: 'SET_BANK', bank: result.bank })
    // }

    // if (result.banks) {
    //   dispatch({ type: 'SET_BANKS', banks: result.banks })
    // }

    // if (result.verification) {
    //   dispatch({ type: 'SET_VERIFICATIONS', verifications: result.verifications })
    // }

    // if (result.user?.auth_required) {
    //   dispatch({ type: 'SET_TWOFA', twoFA: result.user.auth_required })
    // }
  }

  const setTab = (val) => {
    history.push('#' + val)
  }

  useEffect(() => {
    if (!isEmpty(location.hash)) {
      setActiveTab(location.hash.replace('#', ''))
    } else {
      setActiveTab('details')
    }
  }, [location])

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Breadcrumb parent="User Profile" title={tabs[activeTab]} />
      <Container>
        <Row>
          <Col sm="12" lg="8" xl="9" className="project-list">
            <Card>
              <Row>
                <Col>
                  <Nav tabs className="border-tab">
                    <NavItem>
                      <NavLink
                        className={activeTab === 'details' ? 'active' : ''}
                        onClick={() => setTab('details')}
                      >
                        <i className="icofont icofont-wallet"></i>
                        My Details
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === 'verification' ? 'active' : ''}
                        onClick={() => setTab('verification')}
                      >
                        <i className="icofont icofont-ui-check"></i>
                        Verification
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={activeTab === 'password' ? 'active' : ''}
                        onClick={() => setTab('password')}
                      >
                        <i className="icofont icofont-ui-password"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={activeTab === 'authentication' ? 'active' : ''}
                        onClick={() => setTab('authentication')}
                      >
                        <i className="icofont icofont-ui-lock"></i>
                        Two-Factor Authentication
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="8" xl="9">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="details">
                <UserDetail />
              </TabPane>
              <TabPane tabId="bank">
                <BankDetail />
              </TabPane>
              <TabPane tabId="verification">
                <Verification />
              </TabPane>
              <TabPane tabId="password">
                <ChangePassword />
              </TabPane>
              <TabPane tabId="authentication">
                <TwoFactorAuthentication />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default User
