import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'

const HoverCard = styled(Card)`
  cursor: pointer;
  border: 1px solid transparent;
  border-color: ${(props) => {
    return props.isSelected ? '#f9b600' : null
  }};

  &:hover {
    transform: scale(1.01);
    transition: 0.2s;
  }
`

const ResponsiveIcon = styled.i`
  font-size: 32px;
  margin-right: 16px;

  @media (max-width: 575px) {
    margin-right: 0;
  }
`

const ResponsiveImage = styled.img`
  width: 32px;
  margin-right: 16px;

  @media (max-width: 575px) {
    margin-right: 0;
  }
`

const ResponsiveCard = styled(CardBody)`
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const ResponsiveDiv = styled.div`
  text-align: left;

  > h6 {
    margin: 0;
  }

  > p {
    margin: 0;
    color: #818181;
  }

  @media (max-width: 575px) {
    margin-top: 8px;
    text-align: center;
    line-height: 1.1em;

    > p {
      margin-top: 4px;
      line-height: 1.1em;
    }
  }
`

export { HoverCard, ResponsiveCard, ResponsiveIcon, ResponsiveImage, ResponsiveDiv }
