import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import App from './App'
import Authentication from './components/auth'
import ConfigDB from './data/customizer/config'
import { routes } from './route'
import ForgotPassword from './components/forgot_password'
import SignUpForm from './components/auth/sign_up'
import SweetAlert from 'sweetalert2'

import UserService from './network/services/user'
import CurrencyService from './network/services/currency'
import AnnouncementService from './network/services/announcement'

const AppWrapper = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [anim, setAnim] = useState('')
  const [notLoggedIn, setNotLoggedIn] = useState(null)
  const showAnnouncement = useSelector((state) => state.announcement.showAnnouncement)
  const animation = localStorage.getItem('animation') || ConfigDB.data.router_animation || 'fade'

  const init = async () => {
    setAnim(animation)
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed']
    console.disableYellowBox = true

    const storedJwt = localStorage.getItem('token')
    const storedUser = localStorage.getItem('user')

    try {
      if (storedJwt != null && storedUser != null) {
        const updatedUser = await UserService.getMyself()
        // console.log('user', updatedUser)
        dispatch({ type: 'SET_USER', user: updatedUser.user })
        dispatch({ type: 'SET_AGENT', partners: updatedUser.partners })
        dispatch({ type: 'SET_PROFILE', profile: updatedUser.profile ?? {} })

        if (updatedUser.verifications) {
          dispatch({ type: 'SET_VERIFICATIONS', verifications: updatedUser.verifications })
        }

        if (updatedUser.user?.auth_required) {
          dispatch({ type: 'SET_TWOFA', twoFA: updatedUser.user.auth_required }) // set true
        }

        if (updatedUser.banks) {
          dispatch({ type: 'SET_BANKS', banks: updatedUser.banks })
        }

        const currencyResponse = await CurrencyService.get()
        dispatch({
          type: 'SET_CURRENCIES',
          currencies: currencyResponse.currencies
        })

        // const promotionResponse = await PromotionService.getAll()
        // dispatch({ type: 'SET_PROMOTIONS', promotions: promotionResponse.promotions })

        // const accountResponse = await AccountService.getAll()
        // dispatch({ type: 'SET_ACCOUNTS', accounts: accountResponse.accounts })

        setNotLoggedIn(false)
        dispatch({ type: 'SET_ACTIVE_MENU', path: location.pathname })

        // if (is first) show and  dispatch is first false
        // get announcements
        // dispatch to redux
        if (showAnnouncement) {
          const announcementResponse = await AnnouncementService.getAll()
          console.log(announcementResponse)
          dispatch({ type: 'SET_ANNOUNCEMENTS', announcements: announcementResponse.announcements })
          if (announcementResponse.announcements?.length == 0) {
            dispatch({ type: 'SET_ANNOUNCEMENTS_INIT', showAnnouncement: false })
          }
        }
      } else {
        setNotLoggedIn(true)
      }
    } catch (error) {
      console.log(error)
      setNotLoggedIn(storedJwt == null)
      SweetAlert.fire({
        title: error.message,
        icon: 'error'
      })
    }
  }

  // anytime we access the site
  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  // on notLoggedIn changed
  useEffect(() => {
    if (notLoggedIn != null) {
      if (notLoggedIn) {
        if (
          location.pathname !== '/auth' &&
          location.pathname !== '/forgot_password' &&
          location.pathname !== '/sign_up'
        ) {
          history.push('/auth')
        }
      }
    }
    // eslint-disable-next-line
  }, [notLoggedIn])

  return (
    <>
      {notLoggedIn != null ? (
        <Switch>
          {!notLoggedIn ? (
            <App>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                }}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/auth`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
                }}
              />
              <TransitionGroup>
                {routes.map(({ path, Component }) => (
                  <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                    {({ match }) => (
                      <CSSTransition
                        in={match != null}
                        timeout={100}
                        classNames={anim}
                        unmountOnExit
                        exit={false}
                      >
                        <div>
                          <Component />
                        </div>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </TransitionGroup>
            </App>
          ) : (
            <>
              <Route path={`${process.env.PUBLIC_URL}/auth`} component={Authentication} />
              <Route
                path={`${process.env.PUBLIC_URL}/forgot_password`}
                component={ForgotPassword}
              />
              <Route path={`${process.env.PUBLIC_URL}/sign_up`} component={SignUpForm} />
            </>
          )}
        </Switch>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  )
}

export default AppWrapper
