import React, { useState } from 'react'
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import Dropzone from 'react-dropzone-uploader'
import SweetAlert from 'sweetalert2'

import { BASE_URL } from '../../network/constants'
import FeedbackService from '../../network/services/feedback'

const SupportForm = ({ setRefresh }) => {
  const { register, handleSubmit, errors, reset } = useForm()
  const [attachment, setAttachment] = useState(null)

  const handleSupport = async (data) => {
    try {
      data['file_url'] = attachment
      // console.log(data)

      if (data !== '') {
        const result = await FeedbackService.create(data)
        // console.log(result)

        if (result.feedback?.id) {
          reset()
          setAttachment(null)

          SweetAlert.fire({
            title: 'Ticket submitted!',
            text: 'We have receive your query, and support team will assists you soon.',
            icon: 'success'
          }).then(() => {
            window.location.reload()
          })
        }
      } else {
        errors.showMessages()
      }
    } catch (error) {
      SweetAlert.fire({
        title: error?.message ?? 'Please contact customer support',
        icon: 'error'
      })
    }
  }

  const getUploadParams = ({ meta }) => {
    const token = localStorage.getItem('token')

    return {
      url: BASE_URL + '/files',
      headers: { Authorization: `Bearer ${token}` }
    }
  }

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    // upload successfully
    if (status === 'done') {
      if (xhr.response) {
        setAttachment(JSON.parse(xhr?.response).data)
      }
    }

    // remove attachment
    if (status === 'removed') {
      setAttachment(null)
    }
  }

  return (
    <Form className="theme-form" onSubmit={handleSubmit(handleSupport)}>
      <Row>
        <Col>
          <FormGroup>
            <Label>Ticket Type</Label>
            <Input
              type="select"
              name="type"
              className="form-control digits"
              innerRef={register({ required: true })}
            >
              <option value="1">Common Questions</option>
              <option value="2">Payments</option>
              <option value="3">Partnership</option>
              <option value="4">Complaint</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Subject</Label>
            <Input
              className="form-control"
              type="text"
              name="subject"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.subject && 'Subject is required'}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Message</Label>
            <Input
              className="form-control"
              type="textarea"
              name="message"
              innerRef={register({ required: true })}
            />
            <span style={{ color: 'red' }}>{errors.message && 'Message is required'}</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Attachment</Label>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              canCancel={true}
              inputContent="Drop A File"
              styles={{
                dropzone: { width: '100%', height: 50 },
                dropzoneActive: { borderColor: 'green' }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-0">
            <Button color="success" className="mr-3">
              Confirm
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default SupportForm
