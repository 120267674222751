import { combineReducers } from 'redux'
// import Customizer from "./customizer/reducer";
import user from './user/reducer'
import header from './header/reducer'
import sidebar from './sidebar/reducer'
import monitoring from './monitoring/reducer'
import wallet from './wallet/reducer'
import account from './account/reducer'
import currency from './currency/reducer'
import payment from './payment/reducer'
import deposit from './deposit/reducer'
import demo from './demo/reducer'
import live from './live/reducer'
import withdrawal from './withdrawal/reducer'
import verification from './verification/reducer'
import dashboard from './dashboard/reducer'
import promotion from './promotion/reducer'
import announcement from './announcement/reducer'

const reducers = combineReducers({
  // Customizer,
  user,
  header,
  sidebar,
  monitoring,
  wallet,
  account,
  currency,
  payment,
  deposit,
  demo,
  live,
  withdrawal,
  verification,
  dashboard,
  promotion,
  announcement
})

export default reducers
