import React, { useState, useEffect } from 'react'
import { Container, Card, CardBody, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

import AuthService from '../../network/services/auth'
import UserService from '../../network/services/user'

import SettingService from '../../network/services/setting'
import styled from 'styled-components'
import Logo from '../../assets/images/logo/logo.png'

const SubmitButton = styled.button`
  background-color: #0d1731;
  width: 100%;
  color: white;
  border-radius: 5px;
  padding: 5px;
  border: 0;
`

const ForgotPassword = ({ login }) => {
  const history = useHistory()
  const [logo, setLogo] = useState(null)
  const init = async () => {
    try {
      // const result = await SettingService.getAll()
      // // console.log(result)
      // if (result.settings) {
      //   let logo2 = result.settings.find((s) => {
      //     return s.handle === 'crm_logo_url'
      //   })

      //   setLogo(logo2 ?? {})
      // } else {
      //   setLogo({})
      // }
      setLogo(Logo)
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  const { register, handleSubmit, watch, errors, clearErrors, setError } = useForm()
  const watchPassword = watch('password')

  const [email, setEmail] = useState('')
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const submit = async (data) => {
    try {
      await AuthService.verifyCode({
        email: email,
        code: data.auth_code
      })
      const result = await AuthService.forgotPassword({
        email: email,
        auth_code: data.auth_code,
        password: data.password,
        password_confirmation: data.confirm_password
      })
      // console.log(result)

      SweetAlert.fire({
        title: 'Password reset successfully!',
        icon: 'success'
      }).then(() => {
        history.push('/auth')
      })
    } catch (error) {
      console.log(error)

      SweetAlert.fire({
        title: error.message,
        icon: 'error'
      })
    }
  }

  const verifyEmail = async () => {
    if (email == null || email === '') {
      setError('email', {
        type: 'manual',
        message: 'Email is required'
      })
      return
    }

    try {
      const result = await UserService.verifyEmail({
        email: email
      })

      if (!result.success) {
        const codeResult = await AuthService.sendVerification({
          email: email
        })

        if (codeResult.success) {
          setIsEmailVerified(true)
          clearErrors('email')
        } else {
          setError('email', {
            type: 'manual',
            message: codeResult.message ?? 'Please try again later'
          })
          // throw codeResult
        }
      } else {
        // console.log('?')
        setError('email', {
          type: 'manual',
          message: 'Email is not valid or not exists'
        })
        // throw result
      }
    } catch (error) {
      console.log(error)

      SweetAlert.fire({
        title: error.message,
        icon: 'error'
      })
    }
  }
  return (
    <div className="page-wrapper" style={{ height: '100vh' }}>
      <Container
        fluid={true}
        className="p-0"
        style={{ height: '100vh' }}
        style={{
          backgroundPosition: 'bottom',
          backgroundImage:
            'url(https://wota-live-s3.s3-ap-southeast-1.amazonaws.com/loginBackground.png)',
          backgroundSize: 'cover'
        }}
      >
        <CardBody
          className="h-100-d-center"
          style={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          <div className="mb-3" style={{ textAlign: 'center' }}>
            {logo && (
              <Link
                style={{ margin: '0 auto', marginBottom: '30px' }}
                to={`${process.env.PUBLIC_URL}/auth`}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    maxHeight: 175,
                    maxWidth: 175
                  }}
                />
              </Link>
            )}
          </div>

          <div
            className="cont"
            style={{ padding: 40, border: '1px solid #cccccc', width: '600px' }}
          >
            <Form
              onSubmit={handleSubmit(submit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <h4>Forgot Password</h4>
              {/* <p style={{color:'#898989'}}>Please enter your email</p> */}
              <FormGroup>
                <Label className="col-form-label pt-0">Enter Your Email Address</Label>

                <Input
                  id="input-form"
                  style={{ backgroundColor: '#f8f8f8' }}
                  placeholder="Email address"
                  className="form-control"
                  type="text"
                  required=""
                  name="email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  disabled={isEmailVerified ? true : false}
                  innerRef={register({ required: true })}
                />
                <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
              </FormGroup>
              {/* {loginError != null && <span style={{ color: 'red' }}>{loginError}</span>} */}

              {isEmailVerified ? (
                <>
                  <FormGroup>
                    <span
                      style={{ color: 'green' }}
                    >{`Please check ${email} for verification code`}</span>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: '#f8f8f8' }}
                      className="form-control"
                      type="text"
                      placeholder="Verification Code"
                      name="auth_code"
                      innerRef={register({ required: 'Verification Code is required' })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.auth_code && errors.auth_code.message}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: '#f8f8f8' }}
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      name="password"
                      innerRef={register({
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Password minumum length is 8'
                        }
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.password && errors.password.message}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="input-form"
                      style={{ backgroundColor: '#f8f8f8' }}
                      className="form-control"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      innerRef={register({
                        required: 'Confirm Password is required',
                        minLength: 8,
                        validate: (value) => {
                          return value === watchPassword || 'Password does not match'
                        }
                      })}
                    />
                    <span style={{ color: 'red' }}>
                      {errors.confirm_password && errors.confirm_password.message}
                    </span>
                  </FormGroup>
                  <FormGroup className=" mb-0">
                    <Button color="primary btn-block" type="submit">
                      Submit
                    </Button>
                  </FormGroup>
                </>
              ) : (
                <FormGroup className="mb-0">
                  <Button color="primary btn-block" onClick={() => verifyEmail()}>
                    Submit
                  </Button>
                </FormGroup>
              )}
              <div className="mt-3" />
              <div style={{ display: 'flex' }}>
                <p style={{ color: '#898989' }}>Already have an account? </p>
                <Link to={`${process.env.PUBLIC_URL}/auth`} href="#javascript">
                  <p className="ml-1" style={{ color: '#512367' }}>
                    Login
                  </p>
                </Link>
              </div>
            </Form>
          </div>
        </CardBody>
      </Container>
    </div>
  )
}

export default ForgotPassword
