import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, CardBody, Spinner, Card } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import SignInForm from './sign_in'

import SettingService from '../../network/services/setting'
import styled from 'styled-components'

import 'animate.css'
import Logo from '../../assets/images/logo/logo.png'

const SignupButton = styled.button`
  background-color: transparent;
  border: 1px #f9b700 solid;
  color: #f9b700;
  border-radius: 10px;
  width: 40%;
`

const Authentication = () => {
  const history = useHistory()

  const [logo, setLogo] = useState(null)
  const init = async () => {
    try {
      setLogo(Logo)
    } catch (error) {
      setLogo({})
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {logo != null ? (
        <Container
          style={{
            maxWidth: '100%',
            backgroundColor: '#f8f8f8',
            backgroundPosition: 'bottom',
            backgroundImage: `url(https://wota-live-s3.s3-ap-southeast-1.amazonaws.com/loginBackground.png)`,
            backgroundSize: 'cover'
          }}
        >
          <Row className="h-100-d-center m-0">
            <Col lg="12" id="login-col">
              <SignInForm logo={logo} />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container
            style={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spinner />
          </Container>
        </>
      )}
    </>
  )
}

export default Authentication

// ORIGINAL code
