import React from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// import Bookmark from "../bookmark";

const Breadcrumbs = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const navigate = (path) => {
    history.push(path)
    dispatch({ type: 'SET_ACTIVE_MENU', path: path })
  }

  return (
    <>
      <Container fluid="sm">
        <div className="page-header">
          <Row>
            <Col>
              <h3>{props.title}</h3>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link
                    to="#"
                    onClick={() => {
                      navigate(`${process.env.PUBLIC_URL}/dashboard`)
                    }}
                  >
                    <Home />
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>{props.parent}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            {props.action && <Col>{props.action}</Col>}
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Breadcrumbs
